import axios from 'axios';
import {toast} from 'react-toastify';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/users`

//Register User
export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/api/users/register`, userData)
        if(response.statusText === "OK" ) {
            toast.success("User Created Successfully")
        }
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            throw new Error(message);
    }
};

//Login User
export const loginUser = async (userData) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/api/users/login`, userData)
        if(response.statusText === "OK" ) {
            toast.success("User Loggedin Successfully")
        }
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
        toast.error(message);
        throw error;
    }
};

//Logout User
export const logoutUser = async () => {
    try {
     await axios.get(`${BACKEND_URL}/api/users/logout`)
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            toast.error(message)
    }
};

//Get Login Status
export const getLoginStatus = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/api/users/loggedin`)
        return response.data
    } catch (error) {
        localStorage.clear();
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            toast.error(message)
        return false;   
    }
};

//Get Users
export const getUsers = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/api/users/getusers`)
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            toast.error(message)
    }
};

//Delete User
export const deleteUser = async (userId) => {
    try {
        const response = await axios.delete(`${API_URL}/${userId}`);
        if (response.status === 200) { // Checking status code directly
            toast.success("User Deleted Successfully");
        }
    } catch (error) {
        const message = error.response?.data?.message || error.message || error.toString();
        toast.error(message);
    }
};

// Update User
export const updateUser = async (userId, userData) => {
    try {
        const response = await axios.patch(`${API_URL}/${userId}`, userData);
        if (response.status === 200) { // Checking status code directly
            toast.success("User Updated Successfully");
        }
        return response.data;
    } catch (error) {
        const message = error.response?.data?.message || error.message || error.toString();
        toast.error(message);
    }
};