import { FaTh} from "react-icons/fa";
import { TbBuildingWarehouse } from "react-icons/tb";
import { DiAptana } from "react-icons/di";
import { MdOutlineShoppingCart } from "react-icons/md";

const menu = [
  {
    title: "Dashboard",
    icon: <FaTh />,
    path: "/dashboard",
  },
  {
    title: "Warehouse",
    icon: <TbBuildingWarehouse />,
    path: "/getproducts",
  },
  {
    title: "Orders",
    icon: <MdOutlineShoppingCart />,
    childrens: [
      {
        title: "Orders Dashboard",
        path: "/orders",
      },
      {
        title: "Create Order",
        path: "/create-order",
      },
      {
        title: "Return Order",
        path: "/return-order",
      },
    ],
  },
  // {
  //   title: "Reports",
  //   icon: <TbReportSearch />,
  //   path: "/contact-us",
  // },
  {
    title: "System Maintenance",
    icon: <DiAptana />,
    requireAdmin: true,
    childrens: [
      {
        title: "Users",
        path: "/user-maintenance",
      },
      {
        title: "Products",
        path: "/product-maintenance",
      },
      {
        title: "Categories & Warehouses",
        path: "/categories-warehouses",
      },
    ],
  },
];

export default menu;