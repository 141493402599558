import React, { useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import ReturnOrderDetail from '../../components/ReturnOrderDetail/ReturnOrderDetail';
import OrderStatusBadge from '../../components/order/OrderStatusBadge';
import styles from './ReturnOrder.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const filterOrders = (orders, filters, searchTerm) => {
    let result = orders;

    // Status filter
    if (filters.status === 'issuedAndCommitted') {
        result = result.filter(order => 
            order.orderStatus === 'issued' || order.orderStatus === 'committed'
        );
    } else if (filters.status !== 'all') {
        result = result.filter(order => order.orderStatus === filters.status);
    }

    // Date filter
    if (filters.dateRange !== 'all') {
        const now = new Date();
        const daysDiff = {
            '7days': 7,
            '30days': 30,
            '90days': 90
        };
        const days = daysDiff[filters.dateRange];
        if (days) {
            result = result.filter(order => {
                const orderDate = new Date(order.createdAt);
                return (now - orderDate) / (1000 * 60 * 60 * 24) <= days;
            });
        }
    }

    // Search filter
    if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase();
        result = result.filter(order => 
            (order.orderNumber?.toString().includes(searchTermLower)) ||
            (order.destination?.toLowerCase().includes(searchTermLower)) ||
            (order.requestedByName?.toLowerCase().includes(searchTermLower)) || 
            (order.orderName?.toLowerCase().includes(searchTermLower))
        );
    }

    return result;
};

const ReturnOrder = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [filters, setFilters] = useState({
        status: 'issuedAndCommitted',
        dateRange: 'all'
    });



    // Fetch orders
    useEffect(() => {
        const fetchOrders = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${BACKEND_URL}/api/orders/getorders/withUsers`);
                setOrders(response.data || []);
            } catch (error) {
                toast.error('Failed to fetch orders');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchOrders();
    }, []);


    const filteredOrders = useMemo(() => 
        filterOrders(orders, filters, debouncedSearch),
        [orders, filters, debouncedSearch]
    );


    const debouncedSetSearch = useMemo(
        () => debounce((value) => setDebouncedSearch(value), 300),
        [setDebouncedSearch]
    );


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value); 
        debouncedSetSearch(value); 
    };

    const handleReturnOrder = () => {
        setIsReturnModalOpen(true);
    };

    const handleOrderSelect = async (order) => {
        if (!order.requestedByName) {
            try {
                const userResponse = await axios.get(`${BACKEND_URL}/api/users/${order.requestedBy}`);
                order.requestedByName = userResponse.data.name;
                setSelectedOrder({ ...order });
            } catch (error) {
                toast.error('Failed to fetch user information');
                console.error(error);
            }
        } else {
            setSelectedOrder(order);
        }
    };

    const handleReturnSubmit = (returnData) => {
        // Update local state after successful return
        setOrders(prev => prev.map(order => 
            order._id === selectedOrder._id 
                ? { 
                    ...order, 
                    orderStatus: 'returned',
                    products: order.products.map(product => {
                        const returnedProduct = returnData.find(p => p.productId === product._id);
                        return returnedProduct 
                            ? { 
                                ...product, 
                                quantity: product.quantity - returnedProduct.returnQuantity 
                            } 
                            : product;
                    })
                } 
                : order
        ));
        setSelectedOrder(null);
        setIsReturnModalOpen(false);
    };

    
return (
    <div className={styles.container}>
        <div className={styles.header}>
            <h1>Return Order</h1>
        </div>
        {isLoading ? (
            <div className={styles.loadingContainer}>
                <div className={styles.spinner}></div>
                <p>Loading data...</p>
            </div>
        ) : (
        <>

        <div className={styles.filterSection}>
            <input 
                type="text"
                 placeholder="Search by order number, name, destination, or user name..."
                value={searchTerm}
                onChange={handleSearchChange}
                className={styles.searchInput}
            />

            <select 
                value={filters.status}
                onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                className={styles.filterSelect}
            >
                <option value="issuedAndCommitted">Issued & Committed</option>
                <option value="issued">Issued</option>
                <option value="committed">Committed</option>
            </select>

            <select 
                value={filters.dateRange}
                onChange={(e) => setFilters(prev => ({ ...prev, dateRange: e.target.value }))}
                className={styles.filterSelect}
            >
                <option value="all">All Dates</option>
                <option value="7days">Last 7 Days</option>
                <option value="30days">Last 30 Days</option>
                <option value="90days">Last 90 Days</option>
            </select>
        </div>

        <div className={styles.ordersTable}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.tableHeader}>Order Name</th>
                        <th className={styles.tableHeader}>Created Date</th>
                        <th className={styles.tableHeader}>Destination</th>
                        <th className={styles.tableHeader}>Requested By</th>
                        <th className={styles.tableHeader}>Status</th>
                        <th className={styles.tableHeader}>From Date</th>
                        <th className={styles.tableHeader}>Until Date</th>
                    </tr>
                </thead>
                <tbody>
                {filteredOrders.map(order => {
                    const isOverdue = new Date(order.untilDate) < new Date();
                    return (
                        <tr 
                            key={order._id} 
                            onClick={() => handleOrderSelect(order)}
                            className={`
                                ${styles.tableRow}
                                ${selectedOrder?._id === order._id ? styles.selectedRow : ''}
                                ${isOverdue ? styles.overdueRow : ''}
                            `.trim()}
                        >
                            <td className={styles.tableCell}>{order.orderName}</td>
                            <td className={styles.tableCell}>{new Date(order.createdAt).toLocaleDateString('he-IL')}</td>
                            <td className={styles.tableCell}>{order.destination}</td>
                            <td className={styles.tableCell}>{order.requestedByName}</td>
                            <td className={styles.tableCell}><OrderStatusBadge status={order.orderStatus}/></td>
                            <td className={styles.tableCell}>{new Date(order.fromDate).toLocaleDateString('he-IL')}</td>
                            <td className={styles.tableCell}>{new Date(order.untilDate).toLocaleDateString('he-IL')}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>

        {selectedOrder && (
            <div className={styles.returnSection}>
                <h2>Selected Order for Return</h2>
                <div className={styles.selectedOrderDetails}>
                    <p><strong>Order Number:</strong> {selectedOrder.orderNumber}</p>
                    <p><strong>Order Name:</strong> {selectedOrder.orderName}</p>
                    <p><strong>Destination:</strong> {selectedOrder.destination}</p>
                    <p><strong>Requested By:</strong> {selectedOrder.requestedByName}</p>
                    <p><strong>Status:</strong> {selectedOrder.orderStatus}</p>
                    <p><strong>From Date - Until Date:</strong> {new Date(selectedOrder.fromDate).toLocaleDateString('he-IL')} - {new Date(selectedOrder.untilDate).toLocaleDateString('he-IL')}</p>
                </div>
                <button 
                    onClick={handleReturnOrder}
                    className={styles.returnButton}
                >
                    Return Order
                </button>
            </div>
        )}

        {selectedOrder && isReturnModalOpen && (
            <ReturnOrderDetail 
                order={selectedOrder}
                onClose={() => setIsReturnModalOpen(false)}
                onSubmit={handleReturnSubmit}
            />
        )}
        </>
        )}
    </div>
    );
};

export default ReturnOrder;