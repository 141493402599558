import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    AreaChart, Area, PieChart, Pie,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    Cell
} from 'recharts';
import { format, subMonths, parseISO, differenceInMonths} from 'date-fns';
import Loader from '../../loader/Loader';
import styles from './Dashboard.module.css';
import { getCategories } from '../../services/categoryService';
import { getDepartments } from '../../services/departmentService';
import { getWarehouses } from '../../services/warehouseService';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

const Dashboard = () => {
    const [statistics, setStatistics] = useState({
        orders: {
            total: 0,
            committed: 0,
            issued: 0,
            returned: 0,
            partially_returned: 0,
            cancelled: 0,
            averageValue: 0,
            cancellationRate: 0,
            partialReturnRate: 0,
            returnRate: 0
        },
        products: {
            total: 0,
            availableProducts: 0,
            totalItems: 0, 
            availableItems: 0,    
            warehouseBreakdown: {},
            categoryBreakdown: {},
            departmentBreakdown: {}
        },
        users: {
            total: 0,
            active: 0,
            adminUsers: 0,
            regularUsers: 0
        },
        orderTrends: []
    });

    const [orderOverviewTimeRange, setOrderOverviewTimeRange] = useState('6');
    const [orderTrendsTimeRange, setOrderTrendsTimeRange] = useState('6');
    const [isLoading, setIsLoading] = useState(true);
    const [distributionViews, setDistributionViews] = useState({
        warehouse: 'quantity',
        category: 'quantity',
        department: 'quantity'
    });

    const calculateOrderTrends = useCallback((orders, monthsBack) => {
        if (monthsBack === 'all') {
            // For "All Time", get the date of the earliest order
            const earliestDate = new Date(Math.min(...orders.map(o => new Date(o.fromDate))));
            monthsBack = differenceInMonths(new Date(), earliestDate) + 1;
        }
        const trends = [];
        const endDate = new Date();
        const startDate = subMonths(endDate, monthsBack);
    
        for (let i = 0; i < monthsBack; i++) {
            const date = subMonths(endDate, i);
            trends.unshift({
                month: format(date, 'MMM yy'),
                orders: 0
            });
        }
    
        orders.forEach(order => {
            const orderDate = new Date(order.fromDate);
            if (orderDate >= startDate) {
                const monthIndex = trends.findIndex(
                    t => t.month === format(orderDate, 'MMM yy')
                );
                if (monthIndex !== -1) {
                    trends[monthIndex].orders++;
                }
            }
        });
    
        return trends;
    }, []);

    const fetchStatistics = useCallback(async () => {
        setIsLoading(true);
        try {
            const [ordersRes, productsRes, categoriesRes, warehousesRes, departmentsRes, usersRes] = 
                await Promise.all([
                    axios.get(`${BACKEND_URL}/api/orders/getorders`),
                    axios.get(`${BACKEND_URL}/api/products/getproducts`),
                    getCategories(),
                    getWarehouses(),
                    getDepartments(),
                    axios.get(`${BACKEND_URL}/api/users/getusers`)
                ]);

            // Process orders data
            const overviewMonthsBack = parseInt(orderOverviewTimeRange);
            const overviewStartDate = orderOverviewTimeRange === 'all' 
                ? new Date(0) 
                : subMonths(new Date(), overviewMonthsBack);

            const filteredOrders = ordersRes.data.filter(order => {
                const orderDate = parseISO(order.fromDate);
                return orderOverviewTimeRange === 'all' || orderDate >= overviewStartDate;
            });
            const totalValue = filteredOrders.reduce((sum, order) => {
                return sum + order.products.reduce((orderSum, product) => {
                    return orderSum + (product.price * product.quantity);
                }, 0);
            }, 0);
            
            const orderStats = {
                total: filteredOrders.length,
                committed: filteredOrders.filter(o => o.orderStatus === 'committed').length,
                issued: filteredOrders.filter(o => o.orderStatus === 'issued').length,
                returned: filteredOrders.filter(o => o.orderStatus === 'returned').length,
                partially_returned: filteredOrders.filter(o => o.orderStatus === 'partially_returned').length,
                cancelled: filteredOrders.filter(o => o.orderStatus === 'cancelled').length,
                averageValue: filteredOrders.length > 0 ? totalValue / filteredOrders.length : 0,
                cancellationRate: (filteredOrders.filter(o => o.orderStatus === 'cancelled').length / filteredOrders.length) * 100,
                partialReturnRate: (filteredOrders.filter(o => o.orderStatus === 'partially_returned').length / filteredOrders.length) * 100,
                returnRate: (filteredOrders.filter(o => o.orderStatus === 'returned').length / filteredOrders.length) * 100
            };

            // Calculate product distribution
            const productStats = {
                total: productsRes.data.length,
                availableProducts: productsRes.data.filter(p => p.quantityAvailable > 0).length,
                totalItems: productsRes.data.reduce((sum, p) => sum + p.quantity, 0),
                availableItems: productsRes.data.reduce((sum, p) => sum + p.quantityAvailable, 0),
                warehouseBreakdown: {
                    quantity: {},
                    products: {}
                },
                categoryBreakdown: {
                    quantity: {},
                    products: {}
                },
                departmentBreakdown: {
                    quantity: {},
                    products: {}
                }
            };

            productsRes.data.forEach(product => {
                const warehouse = warehousesRes.find(w => w._id === product.warehouse)?.name || 'Unknown';
                const category = categoriesRes.find(c => c._id === product.category)?.name || 'Unknown';
                const department = departmentsRes.find(d => d._id === product.department)?.name || 'Unknown';
            
                // Quantity distribution
                productStats.warehouseBreakdown.quantity[warehouse] = (productStats.warehouseBreakdown.quantity[warehouse] || 0) + product.quantityAvailable;
                productStats.categoryBreakdown.quantity[category] = (productStats.categoryBreakdown.quantity[category] || 0) + product.quantityAvailable;
                productStats.departmentBreakdown.quantity[department] = (productStats.departmentBreakdown.quantity[department] || 0) + product.quantityAvailable;
            
                // Product count distribution
                productStats.warehouseBreakdown.products[warehouse] = (productStats.warehouseBreakdown.products[warehouse] || 0) + 1;
                productStats.categoryBreakdown.products[category] = (productStats.categoryBreakdown.products[category] || 0) + 1;
                productStats.departmentBreakdown.products[department] = (productStats.departmentBreakdown.products[department] || 0) + 1;
            });

            // Calculate user statistics
            const userStats = {
                total: usersRes.data.length,
                active: usersRes.data.filter(u => {
                    if (!u.lastActivity) return false;
                    // Consider user active if last activity was within last 5 minutes
                    const twoMinutesAgo = new Date(Date.now() - 2 * 60 * 1000);
                    return new Date(u.lastActivity) > twoMinutesAgo;
                }).length,
                adminUsers: usersRes.data.filter(u => u.permission === 'admin').length,
                regularUsers: usersRes.data.filter(u => u.permission === 'user').length
            };

            setStatistics({
                orders: orderStats,
                products: productStats,
                users: userStats,
                orderTrends: calculateOrderTrends(ordersRes.data, parseInt(orderTrendsTimeRange)) 
            });

        } catch (error) {
            console.error('Error fetching statistics:', error);
        } finally {
            setIsLoading(false);
        }
    }, [orderOverviewTimeRange,orderTrendsTimeRange, calculateOrderTrends]);

    useEffect(() => {
        fetchStatistics();
    }, [fetchStatistics]);

    if (isLoading) return <Loader />;

    const renderPieChart = (data, title, type) => (
        <div className={styles.chartCard}>
            <div className={styles.chartHeader}>
                <h3>{title}</h3>
                <div className={styles.viewToggle}>
                    <button
                        className={`${styles.toggleButton} ${distributionViews[type] === 'quantity' ? styles.active : ''}`}
                        onClick={() => setDistributionViews(prev => ({ ...prev, [type]: 'quantity' }))}
                    >
                        Items
                    </button>
                    <button
                        className={`${styles.toggleButton} ${distributionViews[type] === 'products' ? styles.active : ''}`}
                        onClick={() => setDistributionViews(prev => ({ ...prev, [type]: 'products' }))}
                    >
                        Products
                    </button>
                </div>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={Object.entries(data[distributionViews[type]]).map(([name, value]) => ({ name, value }))}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {Object.entries(data[distributionViews[type]]).map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Dashboard</h1>
            </div>
            <div className={styles.quickStats}>
                <div className={`${styles.statCard} ${styles.filtered}`}>
                    <div className={styles.cardHeader}>
                        <h3>Orders Overview</h3>
                        <select 
                            value={orderOverviewTimeRange}
                            onChange={(e) => setOrderOverviewTimeRange(e.target.value)}
                            className={styles.select}
                        >
                            <option value="1">Last Month</option>
                            <option value="3">Last 3 Months</option>
                            <option value="6">Last 6 Months</option>
                            <option value="12">Last 12 Months</option>
                            <option value="all">All Time</option>
                        </select>
                    </div>
                    <div className={styles.statGrid}>
                        <div className={`${styles.statItem} ${styles.totalOrders}`}>
                            <span>Total Orders</span>
                            <strong>{statistics.orders.total}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.committed}`}>
                            <span>Committed</span>
                            <strong>{statistics.orders.committed}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.issued}`}>
                            <span>Issued</span>
                            <strong>{statistics.orders.issued}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.returned}`}>
                            <span>Returns</span>
                            <strong>{statistics.orders.returned}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.averageValue}`}>
                            <span>Average Value</span>
                            <strong>${statistics.orders.averageValue.toFixed(2)}</strong>
                        </div>
                    </div>
                </div>
                <div className={`${styles.statCard} ${styles.filtered}`}>
                    <h3>Partial Returns & Cancellations</h3>
                    <div className={styles.timeRangeIndicator}>
                        Showing data for {orderOverviewTimeRange === 'all' ? 'All Time' : `Last ${orderOverviewTimeRange} Month${orderOverviewTimeRange === '1' ? '' : 's'}`}
                    </div>
                    <div className={styles.statGrid}>
                        <div className={`${styles.statItem} ${styles.partiallyReturned}`}>
                            <span>Partial Returns</span>
                            <strong>{statistics.orders.partially_returned}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.returnRate}`}>
                            <span>Partial Return Rate</span>
                            <strong>{statistics.orders.partialReturnRate.toFixed(1)}%</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.cancelled}`}>
                            <span>Cancelled</span>
                            <strong>{statistics.orders.cancelled}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.cancellationRate}`}>
                            <span>Cancellation Rate</span>
                            <strong>{statistics.orders.cancellationRate.toFixed(1)}%</strong>
                        </div>
                    </div>
                </div>

                <div className={styles.statCard}>
                    <h3>Inventory Status</h3>
                    <div className={styles.statGrid}>
                    <div className={`${styles.statItem} ${styles.totalProducts}`}>
                        <span>Total Products</span>
                        <strong>{statistics.products.total}</strong>
                    </div>
                    <div className={`${styles.statItem} ${styles.totalItems}`}>
                        <span>Total Items</span>
                        <strong>{statistics.products.totalItems}</strong>
                    </div>
                    <div className={`${styles.statItem} ${styles.availableProducts}`}>
                        <span>Available Products</span>
                        <strong>{statistics.products.availableProducts}</strong>
                    </div>
                    <div className={`${styles.statItem} ${styles.availableItems}`}>
                        <span>Available Items</span>
                        <strong>{statistics.products.availableItems}</strong>
                    </div>
                    </div>
                </div>

                <div className={styles.statCard}>
                    <h3>Users Stats</h3>
                    <div className={styles.statGrid}>
                        <div className={`${styles.statItem} ${styles.totalUsers}`}>
                            <span>Total Users</span>
                            <strong>{statistics.users.total}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.adminUsers}`}>
                            <span>Admin Users</span>
                            <strong>{statistics.users.adminUsers}</strong>
                        </div>
                        <div className={`${styles.statItem} ${styles.regularUsers}`}>
                            <span>Regular Users</span>
                            <strong>{statistics.users.regularUsers}</strong>
                        </div>
                            <div className={`${styles.statItem} ${styles.activeUsers}`}>
                                <span>Active Users</span>
                                <strong>{statistics.users.active}</strong>
                            </div>
                    </div>
                </div>
            </div>

            <div className={styles.chartsGrid}>
                <div className={styles.chartCard}>
                    <div className={styles.cardHeader}>
                        <h3>Order Trends</h3>
                        <select 
                            value={orderTrendsTimeRange}
                            onChange={(e) => setOrderTrendsTimeRange(e.target.value)}
                            className={styles.select}
                        >
                            <option value="3">Last 3 Months</option>
                            <option value="6">Last 6 Months</option>
                            <option value="12">Last 12 Months</option>
                        </select>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={statistics.orderTrends}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Area 
                                type="monotone"
                                dataKey="orders"
                                stroke="#8884d8"
                                fill="#8884d8"
                                name="Orders"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>

                {renderPieChart(statistics.products.warehouseBreakdown, 'Product Distribution by Warehouse', 'warehouse')}
                {renderPieChart(statistics.products.categoryBreakdown, 'Product Distribution by Category', 'category')}
                {renderPieChart(statistics.products.departmentBreakdown, 'Product Distribution by Department', 'department')}
            </div>
        </div>
    );
};

export default Dashboard;