import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsLoggedIn, selectUser } from '../../redux/features/auth/authSlice';

export const AdminRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }
  
  if (user?.permission !== 'admin') {
    return <Navigate to="/dashboard" />;
  }
  
  return children;
};

export const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }
  
  return children;
};
