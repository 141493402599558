import React, { useState, useEffect } from 'react';
import { RiRegisteredLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { createProduct } from '../../services/productService';
import { getCategories } from '../../services/categoryService';
import { getWarehouses } from '../../services/warehouseService';
import { getDepartments } from '../../services/departmentService';
import SerialNumberBadge from '../../components/product/SerialNumberBadge';
import Loader from '../../loader/Loader';
import { useNavigate } from 'react-router-dom';
import styles from './ProductForm.module.css';

const CreateProduct = () => {
    const navigate = useNavigate();

    const initialState = {
        productName: "",
        productCode: "",
        managedBySerial: false,
        quantity: "",
        serialNumber: [],
        weight: "",
        weightUnit: "kg",
        price: "",
        warehouse: "",
        category: "",
        department: "",
        notes: "",
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [newSerial, setNewSerial] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesData, warehousesData, departmentsData] = await Promise.all([
                    getCategories(),
                    getWarehouses(),
                    getDepartments()
                ]);
                setCategories(categoriesData);
                setWarehouses(warehousesData);
                setDepartments(departmentsData);
            } catch (error) {
                toast.error('Failed to fetch required data');
            }
        };
    
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else if (name === 'price' || name === 'weight') {
            const numValue = value.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal
            setFormData({ ...formData, [name]: numValue });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSerialNumberChange = (e) => {
        setNewSerial(e.target.value);
    };

    const addSerialNumber = () => {
        if (newSerial.trim() === "") {
            toast.error("Serial number cannot be empty.");
            return;
        }
        if (formData.serialNumber.includes(newSerial.trim())) {
            toast.error("Serial number already exists.");
            return;
        }
        const newSerialNumbers = [...formData.serialNumber, newSerial];
        setFormData({ 
            ...formData, 
            serialNumber: newSerialNumbers,
            quantity: String(newSerialNumbers.length) // Update quantity based on serial numbers
        });
        setNewSerial("");
    };
    

    const removeSerialNumber = (index) => {
        const newSerialNumbers = formData.serialNumber.filter((_, idx) => idx !== index);
        setFormData({ 
            ...formData, 
            serialNumber: newSerialNumbers,
            quantity: String(newSerialNumbers.length) // Update quantity based on serial numbers
        });
    };

    const validateForm = () => {
        if (!formData.productName || !formData.productCode || !formData.quantity || 
            !formData.weight || !formData.price || !formData.department ||
            !formData.warehouse || !formData.category || !formData.weightUnit) {
            toast.error("Please fill all required fields.");
            return false;
        }

        const quantityNum = Number(formData.quantity);
        const priceNum = Number(formData.price);
        const weightNum = Number(formData.weight);

        if (isNaN(quantityNum) || quantityNum < 0) {
            toast.error("Please enter a valid quantity.");
            return false;
        }

        if (isNaN(priceNum) || priceNum <= 0) {
            toast.error("Please enter a valid price.");
            return false;
        }

        if (isNaN(weightNum) || weightNum <= 0) {
            toast.error("Please enter a valid weight.");
            return false;
        }

        if (formData.managedBySerial && (quantityNum !== formData.serialNumber.length)) {
            toast.error("Quantity and number of serial numbers must match.");
            return false;
        }

        return true;
    };

    const addProduct = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) return;

        const productData = {
            ...formData,
            quantityAvailable: Number(formData.quantity),
            serialNumberAvailable: formData.serialNumber,
            commitedQuantity: 0
        };
        setIsLoading(true);
        try {
            await createProduct(productData);
            toast.success("Product Created Successfully")
            setFormData(initialState);
            navigate('/product-maintenance');
        } catch (error) {
            toast.error(error.response?.data?.message || error.message || 'Failed to create product.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.formContainer}>
            {isLoading && <Loader />}
            <div className={styles.formCard}>
            <div className={styles.topLine}></div>
            <button 
              className={styles.backButton}
              onClick={() => navigate('/product-maintenance')}
            >
              ← Back
            </button>
                <div className={styles.cardHeader}>
                    <RiRegisteredLine size={28} />
                    <h2 className={styles.title}>Create New Product</h2>
                </div>

                <form onSubmit={addProduct}>
                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Name
                            </label>
                            <input 
                                type="text" 
                                className={styles.formInput}
                                placeholder="e.g., Radio Device X500" 
                                name="productName" 
                                value={formData.productName} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Code
                            </label>
                            <input 
                                type="text" 
                                className={styles.formInput}
                                placeholder="e.g., RDX-500" 
                                name="productCode" 
                                value={formData.productCode} 
                                onChange={handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Serial Number Management
                            </label>
                            <div className={styles.checkboxContainer}>
                                <input 
                                    type="checkbox" 
                                    name="managedBySerial" 
                                    checked={formData.managedBySerial} 
                                    onChange={handleInputChange} 
                                />
                                <span className={styles.checkboxLabel}>
                                    Track each unit with unique serial number
                                </span>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Quantity
                            </label>
                            <input 
                                type="number" 
                                className={styles.formInput}
                                placeholder="Enter total units" 
                                name="quantity" 
                                value={formData.quantity} 
                                onChange={handleInputChange} 
                                disabled={formData.managedBySerial}
                            />
                        </div>
                    </div>

                    {formData.managedBySerial && (
                        <div className={styles.formRow}>
                            <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                                <label className={styles.formLabel}>Serial Numbers</label>
                                <div className={styles.serialContainer}>
                                    <input 
                                        type="text" 
                                        className={`${styles.formInput} ${styles.serialInput}`}
                                        placeholder="Enter serial number" 
                                        value={newSerial} 
                                        onChange={handleSerialNumberChange} 
                                    />
                                    <button 
                                        type="button" 
                                        onClick={addSerialNumber}
                                        className={styles.addSerialBtn}
                                    >
                                        Add Serial
                                    </button>
                                </div>
                                <div className={`${styles.serialTags} ${styles.largerSerialBadge}`}>
                                    {formData.serialNumber.map((serial, idx) => (
                                        <SerialNumberBadge 
                                            key={serial}
                                            serialNumber={serial}
                                            onRemove={() => removeSerialNumber(idx)}
                                            isRemovable={true}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Weight and Unit
                            </label>
                            <div className={styles.inputGroup}>
                                <input 
                                    type="text" 
                                    className={`${styles.formInput} ${styles.weightInput}`}
                                    placeholder="Enter weight" 
                                    name="weight" 
                                    value={formData.weight} 
                                    onChange={handleInputChange} 
                                />
                                <select 
                                    name="weightUnit" 
                                    value={formData.weightUnit} 
                                    onChange={handleInputChange}
                                    className={`${styles.formInput} ${styles.weightUnit}`}
                                >
                                    <option value="kg">Kilograms (kg)</option>
                                    <option value="g">Grams (g)</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Price
                            </label>
                            <div className={styles.inputGroup}>
                                <div className={styles.priceInputContainer}>
                                    <span className={styles.priceSymbol}>$</span>
                                    <input 
                                        type="text" 
                                        className={`${styles.formInput} ${styles.priceInput}`}
                                        placeholder="Enter price" 
                                        name="price" 
                                        value={formData.price} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.formRowThreeColumns}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Warehouse Location
                            </label>
                            <select 
                                name="warehouse" 
                                value={formData.warehouse} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select warehouse</option>
                                {warehouses.map(warehouse => (
                                    <option key={warehouse._id} value={warehouse._id}>
                                        {warehouse.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Department
                            </label>
                            <select 
                                name="department" 
                                value={formData.department} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select department</option>
                                {departments.map(department => (
                                    <option key={department._id} value={department._id}>
                                        {department.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Category
                            </label>
                            <select 
                                name="category" 
                                value={formData.category} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select category</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                            <label className={styles.formLabel}>Additional Notes</label>
                            <textarea 
                                className={styles.textareaInput}
                                placeholder="Enter any additional product information" 
                                name="notes" 
                                value={formData.notes} 
                                onChange={handleInputChange} 
                                rows="4"
                            />
                        </div>
                    </div>

                    <button type="submit" className={styles.submitBtn}>
                        Create Product
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateProduct;