import axios from 'axios';
import {toast} from 'react-toastify';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/products`;

//Get Products
export const getProducts = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/api/products/getproducts`)
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            toast.error(message)
    }
};

//Create Product
export const createProduct = async (formData) => {
    try {
        const response = await axios.post(API_URL, formData)
        if(response.statusText === "OK" ) {
            toast.success("Product Created Successfully")
        }
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            throw new Error(message)
    }
};

//Delete Product
export const deleteProduct = async (productId) => {
    try {
        const response = await axios.delete(`${API_URL}/${productId}`)
        if(response.statusText === "OK" ) {
            toast.success("Product Deleted Successfully")
        }
        return response.data
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            toast.error(message)
    }
};

// Update Product
export const updateProduct = async (productId, updatedData) => {
    try {
        const response = await axios.patch(`${API_URL}/${productId}`, updatedData);
        if (response.statusText === "OK") {
            toast.success("Product Updated Successfully");
        }
        return response.data;
    } catch (error) {
        const message = (
            error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
        toast.error(message);
    }
};


export const checkProductUsage = async (productId) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/api/products/${productId}/check-usage`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
