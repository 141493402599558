import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getProducts } from '../../services/productService';
import { useVirtual } from 'react-virtual';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProductDetailsModal from '../../components/product/ProductDetailsModal';
import styles from './ProductsTable.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProductsTable = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBySerial, setFilterBySerial] = useState('');
    const [filterByCategory, setFilterByCategory] = useState('');
    const [filterByWarehouse, setFilterByWarehouse] = useState('');
    const [filterByDepartment, setFilterByDepartment] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [rowHeight] = useState(40); 

    // Refs for virtual scrolling
    const parentRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchProducts(),
                    fetchCategories(),
                    fetchWarehouses(),
                    fetchDepartments()
                ]);
            } catch (error) {
                toast.error("Failed to fetch data");
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);

    // Reset scroll position when filters change
    useEffect(() => {
        if (parentRef.current) {
            parentRef.current.scrollTop = 0;
        }
    }, [searchTerm, filterBySerial, filterByCategory, filterByWarehouse, filterByDepartment]);


    const fetchProducts = async () => {
        try {
            const data = await getProducts();
            setProducts(data);
        } catch (error) {
            toast.error("Failed to fetch products");
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/categories`);
            setCategories(response.data);
        } catch (error) {
            toast.error("Failed to fetch categories");
        }
    };

    const fetchWarehouses = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/warehouses`);
            setWarehouses(response.data);
        } catch (error) {
            toast.error("Failed to fetch warehouses");
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/departments`);
            setDepartments(response.data);
        } catch (error) {
            toast.error("Failed to fetch departments");
        }
    };


    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
    };

    const getCategoryName = (categoryId) => {
        const category = categories.find(cat => cat._id === categoryId);
        return category ? category.name : 'Unknown';
    };

    const getWarehouseName = (warehouseId) => {
        const warehouse = warehouses.find(wh => wh._id === warehouseId);
        return warehouse ? warehouse.name : 'Unknown';
    };

    const getDepartmentName = (departmentId) => {
        const department = departments.find(dep => dep._id === departmentId);
        return department ? department.name : 'Unknown';
    };

    const filteredProducts = products.filter(product => {
        return (
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.productCode.toLowerCase().includes(searchTerm.toLowerCase())
        ) &&
        (filterBySerial === '' || product.managedBySerial.toString() === filterBySerial) &&
        (filterByCategory === '' || product.category === filterByCategory) &&
        (filterByWarehouse === '' || product.warehouse === filterByWarehouse) &&
        (filterByDepartment === '' || product.department === filterByDepartment)
    });

    const rowVirtualizer = useVirtual({
        size: filteredProducts.length,
        parentRef,
        estimateSize: useCallback(() => rowHeight, [rowHeight]),
        overscan: 5
    });

    const VirtualRow = ({ index, product }) => (
        <>
            <div className={styles.tableCell}>{product.productCode}</div>
            <div className={styles.tableCell}>{product.productName}</div>
            <div className={styles.tableCell}>{product.quantity}</div>
            <div className={styles.tableCell}>{product.quantityAvailable}</div>
            <div className={styles.tableCell}>{getCategoryName(product.category)}</div>
            <div className={styles.tableCell}>{getWarehouseName(product.warehouse)}</div>
            <div className={styles.tableCell}>{getDepartmentName(product.department)}</div>
            <div className={styles.tableCell}>{product.managedBySerial ? 'Yes' : 'No'}</div>
        </>
    );

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Warehouse</h2>
            {isLoading ? (
            <div className={styles.loadingContainer}>
                <div className={styles.spinner}></div>
                <p>Loading data...</p>
            </div>
        ) : (
            <>
            <div className={styles.filtersContainer}>
                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Search Products</label>
                    <input 
                        type="text" 
                        placeholder="Search by name or code..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.filterInput}
                    />
                </div>
                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Serial Management</label>
                    <select 
                        value={filterBySerial} 
                        onChange={(e) => setFilterBySerial(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Category</label>
                    <select 
                        value={filterByCategory} 
                        onChange={(e) => setFilterByCategory(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Categories</option>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Warehouse</label>
                    <select 
                        value={filterByWarehouse} 
                        onChange={(e) => setFilterByWarehouse(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Warehouses</option>
                        {warehouses.map(warehouse => (
                            <option key={warehouse._id} value={warehouse._id}>
                                {warehouse.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Department</label>
                    <select 
                        value={filterByDepartment} 
                        onChange={(e) => setFilterByDepartment(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Departments</option>
                        {departments.map(department => (
                            <option key={department._id} value={department._id}>
                                {department.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={styles.tableWrapper}>
                        {/* Fixed Headers */}
                        <table className={styles.tableHeader}>
                            <thead>
                                <tr>
                                    <th className={styles.tableCell}>Product Code</th>
                                    <th className={styles.tableCell}>Product Name</th>
                                    <th className={styles.tableCell}>Total Quantity</th>
                                    <th className={styles.tableCell}>Available Quantity</th>
                                    <th className={styles.tableCell}>Category</th>
                                    <th className={styles.tableCell}>Warehouse</th>
                                    <th className={styles.tableCell}>Department</th>
                                    <th className={styles.tableCell}>Managed By Serial</th>
                                </tr>
                            </thead>
                        </table>

                        {/* Virtual Scroll Container */}
                        <div 
                            ref={parentRef} 
                            className={styles.virtualScrollContainer}
                        >
                            <div
                                style={{
                                    height: `${rowVirtualizer.totalSize}px`,
                                    width: '100%',
                                    position: 'relative'
                                }}
                            >
                                {rowVirtualizer.virtualItems.map((virtualRow) => {
                                    const product = filteredProducts[virtualRow.index];
                                    return (
                                        <div
                                            key={virtualRow.index}
                                            className={styles.virtualRow}
                                            onClick={() => handleProductClick(product)} 
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: `${rowHeight}px`,
                                                transform: `translateY(${virtualRow.start}px)`
                                            }}
                                        >
                                            <VirtualRow 
                                                index={virtualRow.index} 
                                                product={product} 
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {isModalOpen && selectedProduct && (
                        <ProductDetailsModal 
                            productId={selectedProduct._id} 
                            onClose={() => setIsModalOpen(false)} 
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ProductsTable;