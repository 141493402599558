import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCategories, createCategory, deleteCategory, updateCategory } from '../../services/categoryService';
import { getWarehouses, createWarehouse, deleteWarehouse, updateWarehouse } from '../../services/warehouseService';
import { getDepartments, createDepartment, deleteDepartment, updateDepartment } from '../../services/departmentService';
import { showConfirmDialog, showErrorMessage } from '../../utilis/CustomMessages';
import styles from './CategoriesAndWarehouses.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CategoriesAndWarehouses = () => {
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [newWarehouse, setNewWarehouse] = useState('');
    const [newDepartment, setNewDepartment] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingWarehouse, setEditingWarehouse] = useState(null);
    const [editingDepartment, setEditingDepartment] = useState(null);
    const [editCategoryValue, setEditCategoryValue] = useState('');
    const [editWarehouseValue, setEditWarehouseValue] = useState('');
    const [editDepartmentValue, setEditDepartmentValue] = useState('');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAllData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchProducts(),
                    fetchCategories(),
                    fetchWarehouses(),
                    fetchDepartments()
                ]);
            } catch (error) {
                toast.error('Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchAllData();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await getCategories();
            setCategories(data);
        } catch (error) {
            toast.error('Failed to fetch categories');
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/products/getproducts`);
            setProducts(response.data);
        } catch (error) {
            toast.error('Failed to fetch products');
        }
    };

    const fetchWarehouses = async () => {
        try {
            const data = await getWarehouses();
            setWarehouses(data);
        } catch (error) {
            toast.error('Failed to fetch warehouses');
        }
    };

    const fetchDepartments = async () => {
        try {
            const data = await getDepartments();
            setDepartments(data);
        } catch (error) {
            toast.error('Failed to fetch departments');
        }
    };
    

    const handleAddCategory = async () => {
        if (!newCategory) return;
        try {
            await createCategory({ name: newCategory });
            toast.success('Category added successfully');
            setNewCategory('');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to add category');
        }
    };

    const handleAddWarehouse = async () => {
        if (!newWarehouse) return;
        try {
            await createWarehouse({ name: newWarehouse });
            toast.success('Warehouse added successfully');
            setNewWarehouse('');
            fetchWarehouses();
        } catch (error) {
            toast.error('Failed to add warehouse');
        }
    };

    const handleAddDepartment = async () => {
        if (!newDepartment) return;
        try {
            await createDepartment({ name: newDepartment });
            toast.success('Department added successfully');
            setNewDepartment('');
            fetchDepartments();
        } catch (error) {
            toast.error('Failed to add department');
        }
    };

    const handleDeleteCategory = async (id) => {
        // Find products in this category
        const productsInCategory = products.filter(product => product.category === id);

        if (productsInCategory.length > 0) {
            // Create a formatted list of product codes
            const productList = productsInCategory
                .map(product => `${product.productCode} (${product.productName})`)
                .join('\n');

            // Show error message with product details
            await showErrorMessage(
                'Cannot Delete Category',
                `This category contains the following products:\n\n${productList}\n\nPlease reassign or delete these products before removing the category.`
            );
            return;
        }

        // If no products found, proceed with deletion confirmation
        const confirmed = await showConfirmDialog(
            'Delete Category',
            'Are you sure you want to delete this category?'
        );
        
        if (!confirmed) return;

        try {
            await deleteCategory(id);
            toast.success('Category deleted successfully');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to delete category');
        }
    };

    const handleDeleteWarehouse = async (id) => {
        // Find products in this warehouse
        const productsInWarehouse = products.filter(product => product.warehouse === id);

        if (productsInWarehouse.length > 0) {
            // Create a formatted list of product codes
            const productList = productsInWarehouse
                .map(product => `${product.productCode} (${product.productName})`)
                .join('\n');

            // Show error message with product details
            await showErrorMessage(
                'Cannot Delete Warehouse',
                `This warehouse contains the following products:\n\n${productList}\n\nPlease reassign or delete these products before removing the warehouse.`
            );
            return;
        }

        // If no products found, proceed with deletion confirmation
        const confirmed = await showConfirmDialog(
            'Delete Warehouse', 
            'Are you sure you want to delete this warehouse?'
        );
        
        if (!confirmed) return;

        try {
            await deleteWarehouse(id);
            toast.success('Warehouse deleted successfully');
            fetchWarehouses();
        } catch (error) {
            toast.error('Failed to delete warehouse');
        }
    };

    const handleDeleteDepartment = async (id) => {
        // Find products in this department
        const productsInDepartment = products.filter(product => product.department === id);
    
        if (productsInDepartment.length > 0) {
            // Create a formatted list of product codes
            const productList = productsInDepartment
                .map(product => `${product.productCode} (${product.productName})`)
                .join('\n');
    
            // Show error message with product details
            await showErrorMessage(
                'Cannot Delete Department',
                `This department contains the following products:\n\n${productList}\n\nPlease reassign or delete these products before removing the department.`
            );
            return;
        }
    
        // If no products found, proceed with deletion confirmation
        const confirmed = await showConfirmDialog(
            'Delete Department',
            'Are you sure you want to delete this department?'
        );
        
        if (!confirmed) return;
    
        try {
            await deleteDepartment(id);
            toast.success('Department deleted successfully');
            fetchDepartments();
        } catch (error) {
            toast.error('Failed to delete department');
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category._id);
        setEditCategoryValue(category.name);
    };

    const handleEditWarehouse = (warehouse) => {
        setEditingWarehouse(warehouse._id);
        setEditWarehouseValue(warehouse.name);
    };

    const handleEditDepartment = (department) => {
        setEditingDepartment(department._id);
        setEditDepartmentValue(department.name);
    };

    const handleUpdateCategory = async () => {
        if (!editCategoryValue) return;
        try {
            await updateCategory(editingCategory, { name: editCategoryValue });
            toast.success('Category updated successfully');
            setEditingCategory(null);
            setEditCategoryValue('');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to update category');
        }
    };

    const handleUpdateWarehouse = async () => {
        if (!editWarehouseValue) return;
        try {
            await updateWarehouse(editingWarehouse, { name: editWarehouseValue });
            toast.success('Warehouse updated successfully');
            setEditingWarehouse(null);
            setEditWarehouseValue('');
            fetchWarehouses();
        } catch (error) {
            toast.error('Failed to update warehouse');
        }
    };

    const handleUpdateDepartment = async () => {
        if (!editDepartmentValue) return;
        try {
            await updateDepartment(editingDepartment, { name: editDepartmentValue });
            toast.success('Department updated successfully');
            setEditingDepartment(null);
            setEditDepartmentValue('');
            fetchDepartments();
        } catch (error) {
            toast.error('Failed to update department');
        }
    };

    return (
        <div className={styles.categoriesWarehousesContainer}>
            <h2 className={styles.title}> Manage Categories and Warehouses</h2>
            {isLoading ? (
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <p>Loading data...</p>
                </div>
            ) : (
            <>
            <div className={styles.section}>
                <h4>Categories</h4>
                <input 
                    type="text" 
                    value={newCategory} 
                    onChange={(e) => setNewCategory(e.target.value)} 
                    placeholder="New Category" 
                    className={styles.input}
                />
                <button onClick={handleAddCategory} className={styles.button}>Add Category</button>
                <ul className={styles.ul}>
                    {categories.map(category => (
                        <li key={category._id} className={styles.li}>
                            {editingCategory === category._id ? (
                                <>
                                    <input 
                                        type="text" 
                                        value={editCategoryValue} 
                                        onChange={(e) => setEditCategoryValue(e.target.value)} 
                                        className={styles.input}
                                    />
                                    <div className={styles.editButtonGroup}>
                                        <button onClick={handleUpdateCategory} className={styles.button}>Update</button>
                                        <button onClick={() => setEditingCategory(null)} className={styles.button}>Cancel</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {category.name}
                                    <div className={styles.buttonGroup}>
                                        <button className={styles.editButton} onClick={() => handleEditCategory(category)}>Edit</button>
                                        <button className={styles.deleteButton} onClick={() => handleDeleteCategory(category._id)}>Delete</button>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.section}>
                <h4>Warehouses</h4>
                <input 
                    type="text" 
                    value={newWarehouse} 
                    onChange={(e) => setNewWarehouse(e.target.value)} 
                    placeholder="New Warehouse" 
                    className={styles.input}
                />
                <button onClick={handleAddWarehouse} className={styles.button}>Add Warehouse</button>
                <ul className={styles.ul}>
                    {warehouses.map(warehouse => (
                        <li key={warehouse._id} className={styles.li}>
                            {editingWarehouse === warehouse._id ? (
                                <>
                                    <input 
                                        type="text" 
                                        value={editWarehouseValue} 
                                        onChange={(e) => setEditWarehouseValue(e.target.value)} 
                                        className={styles.input}
                                    />
                                    <div className={styles.editButtonGroup}>
                                        <button onClick={handleUpdateWarehouse} className={styles.button}>Update</button>
                                        <button onClick={() => setEditingWarehouse(null)} className={styles.button}>Cancel</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {warehouse.name}
                                    <div className={styles.buttonGroup}>
                                        <button className={styles.editButton} onClick={() => handleEditWarehouse(warehouse)}>Edit</button>
                                        <button className={styles.deleteButton} onClick={() => handleDeleteWarehouse(warehouse._id)}>Delete</button>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.section}>
                <h4>Departments</h4>
                <input 
                    type="text" 
                    value={newDepartment} 
                    onChange={(e) => setNewDepartment(e.target.value)} 
                    placeholder="New Department" 
                    className={styles.input}
                />
                <button onClick={handleAddDepartment} className={styles.button}>Add Department</button>
                <ul className={styles.ul}>
                    {departments.map(department => (
                        <li key={department._id} className={styles.li}>
                            {editingDepartment === department._id ? (
                                <>
                                    <input 
                                        type="text" 
                                        value={editDepartmentValue} 
                                        onChange={(e) => setEditDepartmentValue(e.target.value)} 
                                        className={styles.input}
                                    />
                                    <div className={styles.editButtonGroup}>
                                        <button onClick={handleUpdateDepartment} className={styles.button}>Update</button>
                                        <button onClick={() => setEditingDepartment(null)} className={styles.button}>Cancel</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {department.name}
                                    <div className={styles.buttonGroup}>
                                        <button className={styles.editButton} onClick={() => handleEditDepartment(department)}>Edit</button>
                                        <button className={styles.deleteButton} onClick={() => handleDeleteDepartment(department._id)}>Delete</button>
                                    </div>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            </>
            )}
        </div>
    );
};

export default CategoriesAndWarehouses;