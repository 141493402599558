import React, { useEffect, useState } from 'react';
import { getProducts, deleteProduct } from '../../services/productService';
import axios from 'axios';
import { checkProductUsage } from '../../services/productService';
import { toast } from 'react-toastify';
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { showConfirmDialog, showWarningMessage, showErrorMessage } from '../../utilis/CustomMessages';
import { RiAddLine } from "react-icons/ri";
import styles from './ProductTableAdmin.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProductTableAdmin = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBySerial, setFilterBySerial] = useState('');
    const [filterByCategory, setFilterByCategory] = useState('');
    const [filterByWarehouse, setFilterByWarehouse] = useState('');
    const [filterByDepartment, setFilterByDepartment] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([
                    fetchProducts(),
                    fetchCategories(),
                    fetchWarehouses(),
                    fetchDepartments()
                ]);
            } catch (error) {
                toast.error("Failed to fetch data");
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const fetchProducts = async () => {
        
        try {
            const data = await getProducts();
            setProducts(data);
        } catch (error) {
            toast.error("Failed to fetch products");
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/categories`);
            setCategories(response.data);
        } catch (error) {
            toast.error("Failed to fetch categories");
        }
    };

    const fetchWarehouses = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/warehouses`);
            setWarehouses(response.data);
        } catch (error) {
            toast.error("Failed to fetch warehouses");
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/departments`);
            setDepartments(response.data);
        } catch (error) {
            toast.error('Failed to fetch departments');
        }
    };

    const handleUpdate = (product) => {
        navigate('/update-product', { state: { product } });
    };

    const handleDelete = async (productId) => {
        try {
            // First check if product is in use
            const usage = await checkProductUsage(productId);
            if (usage.inUse) {
                // Product is in active orders
                const ordersList = usage.orders.map(order => 
                    `Order #${order.orderNumber} (${order.status})`
                ).join(', ');
                
                showWarningMessage(
                    'Cannot Delete Product',
                    `This product is currently in use in the following orders: ${ordersList}`
                );
                return;
            }
    
            // If not in use, proceed with delete confirmation
            const isConfirmed = await showConfirmDialog(
                'Are you sure?', 
                "You won't be able to revert this!"
            );
            
            if (isConfirmed) {
                await deleteProduct(productId);
                setProducts(products.filter(product => product._id !== productId));
            }
        } catch (error) {
            showErrorMessage('Error', 'Failed to delete the product');
        }
    };

    const getCategoryName = (categoryId) => {
        const category = categories.find(cat => cat._id === categoryId);
        return category ? category.name : 'Unknown';
    };

    const getWarehouseName = (warehouseId) => {
        const warehouse = warehouses.find(wh => wh._id === warehouseId);
        return warehouse ? warehouse.name : 'Unknown';
    };

    const getDepartmentName = (departmentId) => {
        const department = departments.find(dep => dep._id === departmentId);
        return department ? department.name : 'Unknown';
    };

    const filteredProducts = products.filter(product => {
        return (
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.productCode.toLowerCase().includes(searchTerm.toLowerCase())
        ) &&
        (filterBySerial === '' || product.managedBySerial.toString() === filterBySerial) &&
        (filterByCategory === '' || product.category === filterByCategory) &&
        (filterByWarehouse === '' || product.warehouse === filterByWarehouse) &&
        (filterByDepartment === '' || product.department === filterByDepartment)
    });

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Warehouse Management</h2>
            {isLoading ? (
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <p>Loading data...</p>
                </div>
            ) : (
            <>
            <div className={styles.filtersContainer}>
                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Search Products</label>
                    <input 
                        type="text" 
                        placeholder="Search by product name or code..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.filterInput}
                    />
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Serial Management</label>
                    <select 
                        value={filterBySerial} 
                        onChange={(e) => setFilterBySerial(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Category</label>
                    <select 
                        value={filterByCategory} 
                        onChange={(e) => setFilterByCategory(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Categories</option>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Warehouse</label>
                    <select 
                        value={filterByWarehouse} 
                        onChange={(e) => setFilterByWarehouse(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Warehouses</option>
                        {warehouses.map(warehouse => (
                            <option key={warehouse._id} value={warehouse._id}>
                                {warehouse.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>Department</label>
                    <select 
                        value={filterByDepartment} 
                        onChange={(e) => setFilterByDepartment(e.target.value)}
                        className={styles.filterSelect}
                    >
                        <option value="">All Departments</option>
                        {departments.map(department => (
                            <option key={department._id} value={department._id}>
                                {department.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.filterGroup}>
                    <label className={styles.filterLabel}>&nbsp;</label>
                    <button 
                        className={styles.createButton}
                        onClick={() => navigate('/createproduct')}
                    >
                        <RiAddLine size={20} />
                        Create Product
                    </button>
                </div>
            </div>
            <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th className={styles.tableCell}>Product Code</th>
                        <th className={styles.tableCell}>Product Name</th>
                        <th className={styles.tableCell}>Total Quantity</th>
                        <th className={styles.tableCell}>Available Quantity</th>
                        <th className={styles.tableCell}>Category</th>
                        <th className={styles.tableCell}>Warehouse</th>
                        <th className={styles.tableCell}>Department</th>
                        <th className={styles.tableCell}>Managed By Serial</th>
                        <th className={styles.tableCell}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProducts.map((product, index) => (
                        <tr key={index} className={styles.tableRow}>
                            <td className={styles.tableCell}>{product.productCode}</td>
                            <td className={styles.tableCell}>{product.productName}</td>
                            <td className={styles.tableCell}>{product.quantity}</td>
                            <td className={`${styles.tableCell} ${
                                product.quantityAvailable === 0 ? styles.outOfStock :
                                product.quantityAvailable < 5 ? styles.lowStock : ''
                            }`}>
                                {product.quantityAvailable}
                            </td>
                            <td className={styles.tableCell}>{getCategoryName(product.category)}</td>
                            <td className={styles.tableCell}>{getWarehouseName(product.warehouse)}</td>
                            <td className={styles.tableCell}>{getDepartmentName(product.department)}</td>
                            <td className={styles.tableCell}>{product.managedBySerial ? 'Yes' : 'No'}</td>
                            <td className={`${styles.tableCell} ${styles.actionColumn}`}>
                                <RiEdit2Line
                                    className={`${styles.actionIcon} ${styles.editIcon}`}
                                    onClick={() => handleUpdate(product)}
                                />
                                <RiDeleteBin6Line
                                    className={`${styles.actionIcon} ${styles.deleteIcon}`}
                                    onClick={() => handleDelete(product._id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </>
            )}
        </div>
    );
};

export default ProductTableAdmin;