import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { RiDeleteBin6Line, RiEdit2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import OrderDetailsModal from '../../components/order/OrderDetailsModal';
import OrderStatusBadge from '../../components/order/OrderStatusBadge';
import { showConfirmDialog } from '../../utilis/CustomMessages';
import { useNavigate } from 'react-router-dom';
import { useVirtual } from 'react-virtual';
import { RiAddLine } from "react-icons/ri"; 
import styles from './OrdersDashBoard.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const OrdersDashBoard = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('committedAndIssued');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState('all');
  const [userFilter, setUserFilter] = useState('myOrders');
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowHeight] = useState(40);
  const parentRef = useRef(null);

  
  const checkAndUpdateOrderStatuses = useCallback(async (orders) => {
    const today = new Date();
    const ordersToUpdate = orders.filter(order => 
      order.orderStatus === 'committed' && new Date(order.fromDate) <= today
    );
    
    await Promise.all(ordersToUpdate.map(async (order) => {
      try {
        await axios.patch(`${BACKEND_URL}/api/orders/${order._id}`, {
          orderName: order.orderName,
          destination: order.destination,
          fromDate: order.fromDate,
          untilDate: order.untilDate,
          products: order.products,
          requestedBy: order.requestedBy,
          orderStatus: 'issued'
        });
      } catch (error) {
        console.error(`Error updating order ${order._id}:`, error);
      }
    }));
    
    try {
      const response = await axios.get(`${BACKEND_URL}/api/orders/getorders`);
      setOrders(response.data || []);
    } catch (error) {
      console.error('Error fetching updated orders:', error);
    }
  }, []);
  
  const fetchCurrentUser = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/users/getuser/current-user`);
      setCurrentUser(response.data);
      setIsAdmin(response.data.permission === 'admin' ? true : false);
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  }, []);
  
  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/users/getusers`);
      setUsers(response.data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, []);
  

  const fetchOrders = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/orders/getorders`);
      const fetchedOrders = response.data || [];
      await checkAndUpdateOrderStatuses(fetchedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }, [checkAndUpdateOrderStatuses]);
  
  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            await Promise.all([
                fetchCurrentUser(),
                fetchUsers(),
                fetchOrders()
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    fetchData();
  }, [fetchCurrentUser, fetchUsers, fetchOrders]);
  
  
  const handleFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleDateRangeFilterChange = (e) => {
    setDateRangeFilter(e.target.value);
  };

  const handleUserFilterChange = (e) => {
    setUserFilter(e.target.value);
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCancelOrder = async (orderId) => {
    const confirmed = await showConfirmDialog('Cancel Order', 'Are you sure you want to cancel this order?');
    if (!confirmed) return;

    try {
      await axios.patch(`${BACKEND_URL}/api/orders/cancel/${orderId}`);
      toast.success('Order cancelled successfully');
      // Refresh orders list
      const response = await axios.get(`${BACKEND_URL}/api/orders/getorders`);
      setOrders(response.data || []);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to cancel order');
      console.error('Error cancelling order:', error);
    }
  };

  const filteredOrders = orders.filter(order => {
    const orderFromDate = new Date(order.fromDate);
    const orderUntilDate = new Date(order.untilDate);
    const isStatusMatch = statusFilter === 'committedAndIssued' 
        ? (order.orderStatus === 'committed' || order.orderStatus === 'issued')
        : statusFilter 
            ? order.orderStatus === statusFilter 
            : true;
    const isStartDateMatch = dateRangeFilter === 'custom' && startDate ? orderFromDate >= new Date(startDate) : true;
    const isEndDateMatch = dateRangeFilter === 'custom' && endDate ? orderUntilDate <= new Date(endDate) : true;
    const isUserMatch = userFilter === 'myOrders' && currentUser ? order.requestedBy === currentUser._id : userFilter === 'allOrders' ? true : order.requestedBy === userFilter;
    const isSearchMatch = 
      (order.orderNumber?.includes(searchQuery) || false) || 
      (order.destination?.toLowerCase().includes(searchQuery.toLowerCase()) || false) ||
      (order.orderName?.toLowerCase().includes(searchQuery.toLowerCase()) || false);
    return isStatusMatch && isStartDateMatch && isEndDateMatch && isUserMatch && isSearchMatch;
  });

  const handleEditOrder = (order) => {
    navigate('/update-order', { state: { order } });
  };

  const rowVirtualizer = useVirtual({
    size: filteredOrders.length,
    parentRef,
    estimateSize: useCallback(() => rowHeight, [rowHeight]),
    overscan: 5
  });

  const VirtualRow = ({ order }) => (
    <>
        <div className={styles.tableCell}>{order.orderName}</div>
        <div className={styles.tableCell}>{order.destination}</div>
        <div className={styles.tableCell}>
            {new Date(order.fromDate).toLocaleDateString('he-IL')}
        </div>
        <div className={styles.tableCell}>
            {new Date(order.untilDate).toLocaleDateString('he-IL')}
        </div>
        <div className={styles.tableCell}>
            <OrderStatusBadge status={order.orderStatus}/>
        </div>
        <div className={styles.tableCell} onClick={(e) => e.stopPropagation()}>
            <div className={styles.actionColumn}>
                {(order.orderStatus === 'issued' || order.orderStatus === 'committed') && (
                    <RiEdit2Line
                        className={`${styles.actionIcon} ${styles.editIcon}`}
                        onClick={() => handleEditOrder(order)}
                    />
                )}
                {isAdmin && (order.orderStatus === 'issued' || order.orderStatus === 'committed') && (
                    <RiDeleteBin6Line
                        className={`${styles.actionIcon} ${styles.deleteIcon}`}
                        onClick={() => handleCancelOrder(order._id)}
                    />
                )}
            </div>
        </div>
    </>
  );

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Orders</h2>
      {isLoading ? (
            <div className={styles.loadingContainer}>
                <div className={styles.spinner}></div>
                <p>Loading data...</p>
            </div>
        ) : (
        <>
      <div className={styles['filter-container']}>
        <label htmlFor="searchQuery">Search:</label>
        <input 
          type="text" 
          id="searchQuery" 
          value={searchQuery} 
          onChange={handleSearchQueryChange} 
          placeholder="Search by Order Number or Destination" 
        />
        <label htmlFor="statusFilter">Filter by Status:</label>
        <select id="statusFilter" value={statusFilter} onChange={handleFilterChange}>
          <option value="committedAndIssued">Committed & Issued</option>
          <option value="committed">Committed</option>
          <option value="issued">Issued</option>
          <option value="returned">Returned</option>
          <option value="partially_returned">Partially Returned</option>
          <option value="cancelled">Cancelled</option>
          <option value="">All</option>
        </select>
        <label htmlFor="dateRangeFilter">Date Range:</label>
        <select id="dateRangeFilter" value={dateRangeFilter} onChange={handleDateRangeFilterChange}>
          <option value="all">All Date Range</option>
          <option value="custom">Custom Date Range</option>
        </select>
        {dateRangeFilter === 'custom' && (
          <>
            <label htmlFor="startDate">Start Date:</label>
            <input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} />
            <label htmlFor="endDate">End Date:</label>
            <input type="date" id="endDate" value={endDate} onChange={handleEndDateChange} />
          </>
        )}
        <label htmlFor="userFilter">Filter by User:</label>
        <select id="userFilter" value={userFilter} onChange={handleUserFilterChange}>
          <option value="myOrders">My Orders</option>
          <option value="allOrders">All Orders</option>
          {users.map(user => (
            <option key={user._id} value={user._id}>
              {`${user.name} (${user.username})`}
            </option>
          ))}
        </select>
        <div className={styles.filterGroup}>
            <button 
                className={styles.createButton}
                onClick={() => navigate('/create-order')}
            >
                <RiAddLine size={20} />
                Create Order
            </button>
        </div>
      </div>
      <div className={styles.tableWrapper}>
                        <table className={styles.tableHeader}>
                            <thead>
                                <tr>
                                    <th>Order Name</th>
                                    <th>Destination</th>
                                    <th>From Date</th>
                                    <th>Until Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                        </table>

                        <div ref={parentRef} className={styles.virtualScrollContainer}>
                            <div
                                style={{
                                    height: `${rowVirtualizer.totalSize}px`,
                                    width: '100%',
                                    position: 'relative'
                                }}
                            >
                                {rowVirtualizer.virtualItems.map((virtualRow) => {
                                    const order = filteredOrders[virtualRow.index];
                                    return (
                                        <div
                                            key={virtualRow.index}
                                            className={styles.virtualRow}
                                            onClick={() => setSelectedOrder(order)}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: `${rowHeight}px`,
                                                transform: `translateY(${virtualRow.start}px)`
                                            }}
                                        >
                                            <VirtualRow order={order} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {selectedOrder && (
                        <OrderDetailsModal 
                            order={selectedOrder} 
                            onClose={() => setSelectedOrder(null)} 
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default OrdersDashBoard;