import React from 'react';
import './SerialNumberBadge.css';

const SerialNumberBadge = ({ serialNumber, onRemove, isRemovable = false }) => {
    return (
        <span className="serial-badge">
            {serialNumber}
            {isRemovable && (
                <button
                    type="button"
                    onClick={() => onRemove(serialNumber)}
                    className="remove-serial-btn"
                >
                    ×
                </button>
            )}
        </span>
    );
};

export default SerialNumberBadge;