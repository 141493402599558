import React from 'react';
import './OrderStatusBadge.css';

const OrderStatusBadge = ({ status }) => {
    const getStatusColor = () => {
        switch (status) {
            case 'committed': return 'blue';
            case 'issued': return 'gray';
            case 'returned': return 'green';
            case 'partially_returned': return 'orange';
            case 'cancelled': return 'red';
            default: return 'green';
        }
    };

    return (
        <div className={`status-badge ${getStatusColor()}`}>
            <span className="status-text">{status.replace('_', ' ')}</span>
        </div>
    );
};

export default OrderStatusBadge;