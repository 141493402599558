import React, { useEffect } from 'react';
import styles from './Sidebar.module.css';
import menu from '../../data/sidebarData';
import SidebarItem from './SidebarItem';
import { useState } from 'react';
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/features/auth/authSlice';
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from 'react-router-dom';

const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true); // Default to open on desktop
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation();
    
    const user = useSelector(selectUser);
    const isAdmin = user?.permission === 'admin';

    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            if (!mobile) {
                setIsOpen(true); // Always open on desktop
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Close sidebar only on mobile when route changes
    useEffect(() => {
        if (isMobile) {
            setIsOpen(false);
        }
    }, [location, isMobile]);

    const toggle = () => {
        if (isMobile) {
            setIsOpen(!isOpen);
        } else {
            setIsOpen(!isOpen); // For desktop, this just toggles between wide and narrow
        }
    };

    const filteredMenu = menu.filter(item => !item.requireAdmin || (item.requireAdmin && isAdmin));

    return (
        <div className={styles.layout}>
            {isMobile && (
                <div className={styles.mobileHeader}>
                    <div className={styles.mobileHeaderContent}>
                        <GiHamburgerMenu 
                            className={styles.hamburger} 
                            onClick={toggle}
                        />
                    </div>
                </div>
            )}
            
            <div className={`${styles.sidebar} ${isOpen ? '' : styles.sidebarCollapsed} ${isMobile ? isOpen ? styles.sidebarOpen : '' : ''}`}>
                <div className={styles.topSection}>
                    <div className={styles.logo} style={{display: isOpen ? "none" : "block"}}>
                        <FaArrowCircleRight style={{cursor: "pointer"}} onClick={toggle}/>
                    </div>
                    <div className={styles.bars} style={{marginLeft: isOpen ? "150px" : "none"}}>
                        <FaArrowCircleLeft onClick={toggle}/>
                    </div>
                </div>
                {filteredMenu.map((item, index) =>{
                    return <SidebarItem 
                        key={index} 
                        item={item} 
                        isOpen={isOpen} 
                        setIsOpen={setIsOpen}
                        styles={styles}
                    />
                })}
            </div>  
            
            <main className={styles.mainContent}>
                {children}
            </main>
        </div>
    );
}

export default Sidebar;