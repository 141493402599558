import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/categories';

export const getCategories = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const createCategory = async (categoryData) => {
    const response = await axios.post(API_URL, categoryData);
    return response.data;
};

export const deleteCategory = async (categoryId) => {
    const response = await axios.delete(`${API_URL}/${categoryId}`);
    return response.data;
};

export const updateCategory = async (categoryId, categoryData) => {
    const response = await axios.put(`${API_URL}/${categoryId}`, categoryData);
    return response.data;
};