import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LuLogIn } from "react-icons/lu";
import { HiOutlineChartBar } from "react-icons/hi";
import { BsClockHistory } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { SET_LOGIN, SET_NAME, SET_USER } from '../../redux/features/auth/authSlice';
import { loginUser, getLoginStatus } from '../../services/authService';
import Loader from '../../loader/Loader';
import styles from './AuthPage.module.css';

const AuthPage = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const checkAuth = async () => {
      if (isLoggedIn) {
        const status = await getLoginStatus();
        if (status) {
          navigate('/dashboard');
        }
      }
      setIsLoading(false); 
    };
    
    checkAuth();
  }, [isLoggedIn, navigate]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { username, password } = formData;

    if (!username || !password) {
      return toast.error("All fields are required");
    }
    if (username.length !== 7) {
      return toast.error("Please enter a valid username (employee number)");
    }

    setIsLoading(true);
    try {
      const data = await loginUser({ username, password });
      if (data) {
        await dispatch(SET_LOGIN(true));
        await dispatch(SET_NAME(data.name));
        await dispatch(SET_USER(data));
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      
      <div className={styles.content}>
        <div className={`${styles.panel} ${styles.leftPanel} ${showLogin ? styles.slideOut : ''}`}>
          <nav className={styles.navbar}>
            <div className={styles.logo}>IMS Presale</div>
          </nav>
          
          <div className={styles.heroContent}>
            <h1>Transform Your Inventory Management</h1>
            <p className={styles.subheading}>
              Streamline operations, boost efficiency, and make data-driven decisions 
              with our comprehensive inventory management solution.
            </p>

            <div className={styles.features}>
              <div className={styles.featureCard}>
                <HiOutlineChartBar className={styles.featureIcon} />
                <h3>Real-Time Analytics</h3>
                <p>Monitor stock levels and track performance metrics in real-time</p>
              </div>
              
              <div className={styles.featureCard}>
                <BsClockHistory className={styles.featureIcon} />
                <h3>Automated Updates</h3>
                <p>Save time with automatic inventory tracking and notifications</p>
              </div>
              
              <div className={styles.featureCard}>
                <TbReportAnalytics className={styles.featureIcon} />
                <h3>Smart Reporting</h3>
                <p>Generate insights with customizable reports and forecasts</p>
              </div>
            </div>

            <button 
              className={styles.actionButton}
              onClick={() => setShowLogin(true)}
            >
              Get Started
            </button>
          </div>
        </div>

        <div className={`${styles.panel} ${styles.rightPanel} ${showLogin ? styles.slideIn : ''}`}>
          <div className={styles.blurredBackground}>
            <img src="/homePagePhoto.jpg" alt="" />
          </div>
          <div className={styles.loginCard}>
            <div className={styles.topLine}></div>
            <button 
              className={styles.backButton}
              onClick={() => setShowLogin(false)}
            >
              ← Back
            </button>
            <div className={styles.iconContainer}>
              <LuLogIn size={35} />
            </div>
            <h2>Welcome Back</h2>
            <form onSubmit={handleLogin}>
              <div className={styles.inputGroup}>
                <label htmlFor="username">Username
                <span className={styles.required}>*</span>
                </label>
                <input
                  id="username"
                  type="text"
                  placeholder="Enter your employee number"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="password">Password
                <span className={styles.required}>*</span>
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type="submit" className={styles.actionButton}>
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;