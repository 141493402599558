import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import OrderStatusBadge from './OrderStatusBadge';
import { toast } from 'react-toastify';
import './OrderDetailsModal.css';
import SerialNumberBadge from '../product/SerialNumberBadge';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const OrderDetailsModal = ({ order, onClose }) => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const isReturnOrder = order.orderStatus === 'returned' || order.orderStatus === 'partially_returned';
    const overlayRef = useRef(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/users/${order.requestedBy}`);
                setUserDetails(response.data);
            } catch (error) {
                console.error('Error fetching user details:', error);
                toast.error('Failed to fetch user information');
            } finally {
                setIsLoading(false);
            }
        };

        if (order?.requestedBy) {
            fetchUserDetails();
        }
    }, [order]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && overlayRef.current === event.target) {
                onClose();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);

    if (!order || isLoading) return <div className="loader"></div>;

    const convertToKg = (weight, unit) => {
        if (unit === 'g') {
            const weightInKg = weight / 1000;
            return weightInKg < 1 ? { weight, unit: 'g' } : { weight: weightInKg, unit: 'kg' };
        }
        return { weight, unit };
    };

    const totalWeightInKg = order.products.reduce((sum, product) => {
        const { weight, unit } = convertToKg(product.weight, product.weightUnit);
        return sum + (product.quantity * (unit === 'g' ? weight / 1000 : weight));
    }, 0);

    const totalWeight = totalWeightInKg < 1 ? { weight: totalWeightInKg * 1000, unit: 'g' } : { weight: totalWeightInKg, unit: 'kg' };

    return (
        <div className="modal-overlay" ref={overlayRef}>
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>×</button>
                
                {/* Header Section with Order Info and Status */}
                <div className="modal-header">
                    <div className="order-basic-info">
                        <h2>Order Details</h2>
                        <p className="order-name">{order.orderName}</p>
                        <p className="order-number">#{order.orderNumber}</p>
                        <p className="order-dates">
                            {new Date(order.fromDate).toLocaleDateString('he-IL')} - {new Date(order.untilDate).toLocaleDateString('he-IL')}
                        </p>
                    </div>
                    <OrderStatusBadge status={order.orderStatus}/>
                </div>

                <div className="order-details">
                    {/* User Information Section */}
                    <div className="user-info-section">
                        <h3>Requested By</h3>
                        <div className="user-info-grid">
                            <div className="user-info-item">
                                <span className="info-label">Name:</span>
                                <span className="info-value">{userDetails?.name || 'N/A'}</span>
                            </div>
                            <div className="user-info-item">
                                <span className="info-label">Username:</span>
                                <span className="info-value">{userDetails?.username || 'N/A'}</span>
                            </div>
                            <div className="user-info-item">
                                <span className="info-label">Email:</span>
                                <span className="info-value">{userDetails?.email || 'N/A'}</span>
                            </div>
                        </div>
                    </div>

                    {/* Order Information */}
                    <div className="destination-info">
                        <h3>Destination</h3>
                        <p>{order.destination}</p>
                    </div>

                    {/* Products Table */}
                    <div className="products-section">
                        <h3>Products</h3>
                        <table className="products-table">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Product Code</th>
                                    <th>Quantity</th>
                                    <th>Serial Number</th>
                                    {/* <th>Price</th> */}
                                    <th>Total Price</th>
                                    <th>Total Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.products.map(product => {
                                    const totalPrice = product.quantity * product.price;
                                    const { weight, unit } = convertToKg(product.weight, product.weightUnit);
                                    const totalWeight = product.quantity * (unit === 'g' ? weight / 1000 : weight);
                                    const totalWeightFinal = totalWeight < 1 ? { weight: totalWeight * 1000, unit: 'g' } : { weight: totalWeight, unit: 'kg' };
                                    
                                    return (
                                        <tr key={product._id}>
                                            <td>{product.productName}</td>
                                            <td>{product.productCode}</td>
                                            <td>{product.quantity}</td>
                                            <td>
                                                {product.serialNumbers?.length > 0 ? (
                                                    <div className="serial-badges">
                                                        {product.serialNumbers.map(serial => (
                                                            <SerialNumberBadge key={serial} serialNumber={serial} />
                                                        ))}
                                                    </div>
                                                ) : 'N/A'}
                                            </td>
                                            {/* <td>${product.price.toFixed(2)}</td> */}
                                            <td>${totalPrice.toFixed(2)}</td>
                                            <td>{totalWeightFinal.weight.toFixed(totalWeightFinal.unit === 'g' ? 0 : 2)} {totalWeightFinal.unit}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="4" className="text-right"><strong>Order Totals:</strong></td>
                                    <td>
                                        <strong>
                                            ${order.products.reduce((sum, p) => sum + (p.quantity * p.price), 0).toFixed(2)}
                                        </strong>
                                    </td>
                                    <td>
                                        <strong>
                                            {totalWeight.weight.toFixed(totalWeight.unit === 'g' ? 0 : 2)} {totalWeight.unit}
                                        </strong>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    {/* Return Details Table (only for returned orders) */}
                    {isReturnOrder && (
                        <div className="return-details-section">
                            <h3>Return Details</h3>
                            <table className="return-details-table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Product Code</th>
                                        <th>Asked Qty</th>
                                        <th>Returned Qty</th>
                                        <th>Missing Qty</th>
                                        <th>Missing Serial Numbers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.products.map(product => (
                                        <tr key={product._id}>
                                            <td>{product.productName}</td>
                                            <td>{product.productCode}</td>
                                            <td>{product.quantity}</td>
                                            <td className="returned-qty">{product.returnedQuantity || 0}</td>
                                            <td className="missing-qty">{product.missingQuantity || 0}</td>
                                            <td className="missing-serials">
                                                {product.missingSerialNumbers.length > 0 ? (
                                                    <div className="serial-badges">
                                                        {product.missingSerialNumbers.map(serial => (
                                                            <SerialNumberBadge 
                                                                key={serial} 
                                                                serialNumber={serial}
                                                                isRemovable={false}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderDetailsModal;