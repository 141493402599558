import React from 'react';
import { logoutUser } from '../../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_LOGIN, selectName } from '../../redux/features/auth/authSlice';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import styles from './Header.module.css';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = useSelector(selectName);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate('/');
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerBackground}></div>
      <div className={styles.container}>
        <h3 className={styles.welcomeMessage}>
          <span className={styles.welcomePrefix}>Welcome,</span>
          <span className={styles.userName}>{name}</span>
        </h3>
        <button onClick={logout} className={styles.logoutButton}>
          <RiLogoutCircleRLine className={styles.logoutIcon} />
          <span>Logout</span>
        </button>
      </div>
    </header>
  );
}

export default Header;