import React, { useState } from 'react';
import { RiRegisteredLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { registerUser } from '../../services/authService';
import Loader from '../../loader/Loader';
import { useNavigate } from 'react-router-dom';
import styles from './AuthPage.module.css';  // Reusing the same module CSS

const Register = () => {
    const navigate = useNavigate();
    const initialState = {
        name: "",
        username: "",
        password: "",
        email: "",
        isAdmin: false,
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const { name, username, password, email, isAdmin } = formData;

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const register = async (e) => {
        e.preventDefault();

        if (!name || !username || !password || !email) {
            return toast.error("All fields are required");
        }
        if (username.length !== 7) {
            return toast.error("Please enter a valid username (employee number)");
        }

        const userData = {
            name,
            username,
            password,
            email,
            permission: isAdmin ? 'admin' : 'user'
        };

        setIsLoading(true);
        try {
            await registerUser(userData);
            toast.success("User Created Successfully");
            navigate('/user-maintenance');
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            {isLoading && <Loader />}
            <div className={styles.content}>
                <div className={styles.panel}>
                    <div className={styles.blurredBackground}>
                        <img src="/homePagePhoto.jpg" alt="" />
                    </div>
                    <div className={styles.loginCard}>
                        <div className={styles.topLine}></div>
                        <button
                            className={styles.backButton}
                            onClick={() => navigate('/user-maintenance')}
                        >
                            ← Back
                        </button>
                        <div className={styles.iconContainer}>
                            <RiRegisteredLine size={35} />
                        </div>
                        <h2>Create New User</h2>
                        <form onSubmit={register}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="name">
                                    Name
                                    <span className={styles.required}>*</span>
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Enter full name"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className={styles.inputGroup}>
                                <label htmlFor="username">
                                    Username
                                    <span className={styles.required}>*</span>
                                </label>
                                <input
                                    id="username"
                                    type="text"
                                    placeholder="Enter employee number"
                                    name="username"
                                    value={username}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className={styles.inputGroup}>
                                <label htmlFor="password">
                                    Password
                                    <span className={styles.required}>*</span>
                                </label>
                                <input
                                    id="password"
                                    type="password"
                                    placeholder="Enter password"
                                    name="password"
                                    value={password}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className={styles.inputGroup}>
                                <label htmlFor="email">
                                    Email
                                    <span className={styles.required}>*</span>
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Enter email address"
                                    name="email"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className={styles.checkboxGroup}>
                                <label htmlFor="isAdmin" className={styles.checkboxLabel}>
                                    <input
                                        id="isAdmin"
                                        type="checkbox"
                                        name="isAdmin"
                                        checked={isAdmin}
                                        onChange={handleInputChange}
                                    />
                                    <span>Admin Access</span>
                                </label>
                            </div>

                            <button type="submit" className={styles.actionButton}>
                                Create User
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;