import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RiRegisteredLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { updateProduct } from '../../services/productService';
import { getCategories } from '../../services/categoryService';
import { getWarehouses } from '../../services/warehouseService';
import { getDepartments } from '../../services/departmentService';
import SerialNumberBadge from '../../components/product/SerialNumberBadge';
import Loader from '../../loader/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../utilis/CustomMessages';
import styles from './ProductForm.module.css'; 


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UpdateProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const initialState = location.state?.product || {
        productName: "",
        productCode: "",
        managedBySerial: false,
        quantity: "",
        quantityAvailable: "",
        serialNumber: [],
        serialNumberAvailable: [],
        weight: "",
        weightUnit: "kg",
        price: "",
        warehouse: "",
        category: "",
        department: "",
        notes: "",
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [newSerial, setNewSerial] = useState("");
    const [activeOrders, setActiveOrders] = useState([]);
    const [originalQuantity, setOriginalQuantity] = useState(0);
    const [categories, setCategories] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        if (!location.state?.product) {
            navigate('/product-maintenance');
            toast.error("No product data provided for update.");
            return;
        }

        const product = location.state.product;
        setOriginalQuantity(product.quantity);
        fetchActiveOrders(product._id);

        const fetchCategoriesAndWarehouses = async () => {
            try {
                const [categoriesData, warehousesData, departmentsData] = await Promise.all([
                    getCategories(),
                    getWarehouses(),
                    getDepartments()
                ]);
                setCategories(categoriesData);
                setWarehouses(warehousesData);
                setDepartments(departmentsData);
            } catch (error) {
                toast.error('Failed to fetch required data');
            }
        };

        fetchCategoriesAndWarehouses();
    }, [location, navigate]);

    const fetchActiveOrders = async (productId) => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/orders/getorders`);
            const activeOrders = response.data.filter(order => 
                (order.orderStatus === 'committed' || order.orderStatus === 'issued') &&
                order.products.some(p => p.product === productId)
            );
            setActiveOrders(activeOrders);
        } catch (error) {
            toast.error("Failed to fetch active orders");
            console.error("Error fetching orders:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        if (name === 'quantity') {
            handleQuantityChange(e);
            return;
        }

        if (type === 'checkbox') {
            setFormData(prev => ({ ...prev, [name]: checked }));
        } else if (name === 'price' || name === 'weight') {
            const numValue = value.replace(/[^0-9.]/g, '');
            setFormData(prev => ({ ...prev, [name]: numValue }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleQuantityChange = (e) => {
        const newQuantity = parseInt(e.target.value) || 0;
        
        setFormData(prev => ({
            ...prev,
            quantity: newQuantity,
        }));
    };

    const handleSerialNumberChange = (e) => {
        setNewSerial(e.target.value);
    };

    const addSerialNumber = () => {
        if (newSerial.trim() === "") {
            showErrorMessage('Invalid Input', 'Serial number cannot be empty.');
            return;
        }
    
        if (formData.serialNumber.includes(newSerial)) {
            showErrorMessage(
                'Duplicate Serial',
                'This serial number is already added to the product.'
            );
            return;
        }
    
        setFormData(prev => ({
            ...prev,
            serialNumber: [...prev.serialNumber, newSerial],
            serialNumberAvailable: [...prev.serialNumberAvailable, newSerial],
            quantity: prev.serialNumber.length + 1,
            quantityAvailable: prev.serialNumberAvailable.length + 1
        }));
        setNewSerial("");
    };

    const removeSerialNumber = async (index) => {
        const serialToRemove = formData.serialNumber[index];
    
        const ordersUsingSerial = activeOrders.filter(order => {
            return order.products.some(product => {
                const hasSerial = product.serialNumbers && 
                                product.serialNumbers.includes(serialToRemove) &&
                                product.product === formData._id;
                if (hasSerial) {
                    console.log(`Serial ${serialToRemove} found in order ${order.orderNumber}`);
                }
                return hasSerial;
            });
        });
    
        if (ordersUsingSerial.length > 0) {
            console.log('Orders using serial:', ordersUsingSerial);
            const orderNumbers = ordersUsingSerial.map(order => order.orderNumber).join(", ");
            
            try {
                await showWarningMessage(
                    'Serial Number In Use',
                    `This serial number (${serialToRemove}) is currently in use in the following orders: ${orderNumbers}. Cannot remove serial numbers that are actively being used.`
                );
            } catch (error) {
                console.error('Error showing warning message:', error);
            }
            return;
        }
    
        const newSerialNumbers = formData.serialNumber.filter((_, idx) => idx !== index);
        const newAvailableSerials = formData.serialNumberAvailable.filter(s => s !== serialToRemove);
    
        setFormData(prev => ({
            ...prev,
            serialNumber: newSerialNumbers,
            serialNumberAvailable: newAvailableSerials,
            quantity: newSerialNumbers.length,
            quantityAvailable: newAvailableSerials.length
        }));
    };

    const validateForm = async () => {
        if (!formData.productName || !formData.productCode || !formData.quantity || 
            !formData.weight || !formData.price || !formData.department || 
            !formData.warehouse || !formData.category || !formData.weightUnit) {
            toast.error("Please fill all required fields.");
            return false;
        }
    
        const quantityNum = Number(formData.quantity);
        const priceNum = Number(formData.price);
        const weightNum = Number(formData.weight);
    
        // Basic numeric validation
        if (isNaN(quantityNum) || quantityNum < 0) {
            showErrorMessage('Invalid Input', "Please enter a valid quantity.");
            return false;
        }
    
        if (isNaN(priceNum) || priceNum <= 0) {
            showErrorMessage('Invalid Input', "Please enter a valid price.");
            return false;
        }
    
        if (isNaN(weightNum) || weightNum <= 0) {
            showErrorMessage('Invalid Input', "Please enter a valid weight.");
            return false;
        }
    
        // Special handling for serial-managed products
        if (formData.managedBySerial) {
            // Get all serials currently in use by active orders
            const serialsInUse = new Set();
            activeOrders.forEach(order => {
                order.products.forEach(p => {
                    if (p.product === formData._id && p.serialNumbers) {
                        p.serialNumbers.forEach(serial => serialsInUse.add(serial));
                    }
                });
            });
    
            // Check if all serials in use are still present in the product's serial numbers
            const missingSerials = Array.from(serialsInUse).filter(
                serial => !formData.serialNumber.includes(serial)
            );
    
            if (missingSerials.length > 0) {
                showErrorMessage(
                    'Invalid Serial Number Removal',
                    `Cannot remove the following serial numbers as they are in use: ${missingSerials.join(", ")}`
                );
                return false;
            }
    
            // At this point, we know all serials in use are preserved
            // We don't need to check quantity against active orders because
            // we've already verified we're only removing unused serials
            
            // Just verify the quantities match the serial numbers
            if (formData.serialNumber.length !== quantityNum) {
                showErrorMessage(
                    'Quantity Mismatch',
                    'The number of serial numbers must match the total quantity.'
                );
                return false;
            }
        } else {
            // For non-serial products, check quantity reduction against active orders
            if (quantityNum < originalQuantity) {
                const ordersUsingProduct = activeOrders.filter(order => 
                    order.products.some(p => p.product === formData._id)
                );
    
                if (ordersUsingProduct.length > 0) {
                    const orderNumbers = ordersUsingProduct.map(order => order.orderNumber).join(", ");
                    await showWarningMessage(
                        'Cannot Reduce Quantity',
                        `This product is currently in use in the following orders: ${orderNumbers}. 
                         Please handle these orders first before reducing the quantity.`
                    );
                    return false;
                }
            }
        }
    
        return true;
    };

    const updateExistingProduct = async (e) => {
        e.preventDefault();
        
        if (!(await validateForm())) return;
    
        let productData;
        if (formData.managedBySerial) {
            // For serial-managed products, use the serial numbers to determine quantities
            productData = {
                ...formData,
                quantity: formData.serialNumber.length,
                quantityAvailable: formData.serialNumberAvailable.length,
                serialNumber: formData.serialNumber,
                serialNumberAvailable: formData.serialNumberAvailable
            };
        } else {
            // For non-serial products, use the quantity field
            const quantityDiff = Number(formData.quantity) - originalQuantity;
            const newQuantityAvailable = Math.max(0, formData.quantityAvailable + quantityDiff);
            
            productData = {
                ...formData,
                quantity: Number(formData.quantity),
                quantityAvailable: newQuantityAvailable
            };
        }
    
        setIsLoading(true);
        try {
            await updateProduct(formData._id, productData);
            showSuccessMessage(
                'Success',
                'Product Updated Successfully'
            );
            navigate('/product-maintenance');
        } catch (error) {
            showErrorMessage(
                'Update Failed',
                error.response?.data?.message || error.message || 'Failed to update product.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.formContainer}>
            {isLoading && <Loader />}
            <div className={styles.formCard}>
            <div className={styles.topLine}></div>
            <button 
              className={styles.backButton}
              onClick={() => navigate('/product-maintenance')}
            >
              ← Back
            </button>
                <div className={styles.cardHeader}>
                    <RiRegisteredLine size={28} />
                    <h2 className={styles.title}>Update Product</h2>
                </div>

                <form onSubmit={updateExistingProduct}>
                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Name
                            </label>
                            <input 
                                type="text" 
                                className={styles.formInput}
                                placeholder="e.g., Radio Device X500" 
                                name="productName" 
                                value={formData.productName} 
                                onChange={handleInputChange} 
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Code
                            </label>
                            <input 
                                type="text" 
                                className={styles.formInput}
                                placeholder="e.g., RDX-500" 
                                name="productCode" 
                                value={formData.productCode} 
                                onChange={handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={styles.formLabel}>
                                Serial Number Management
                            </label>
                            <div className={styles.checkboxContainer}>
                                <input 
                                    type="checkbox" 
                                    name="managedBySerial" 
                                    checked={formData.managedBySerial} 
                                    onChange={handleInputChange}
                                    disabled={true} // Disabled in update mode
                                />
                                <span className={styles.checkboxLabel}>
                                    Track each unit with unique serial number
                                </span>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Quantity
                            </label>
                            <input 
                                type="number" 
                                className={styles.formInput}
                                placeholder="Enter total units" 
                                name="quantity" 
                                value={formData.quantity} 
                                onChange={handleInputChange}
                                disabled={formData.managedBySerial} 
                            />
                        </div>
                    </div>

                    {formData.managedBySerial && (
                        <div className={styles.formRow}>
                            <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                                <label className={styles.formLabel}>Serial Numbers</label>
                                <div className={styles.serialContainer}>
                                    <input 
                                        type="text" 
                                        className={`${styles.formInput} ${styles.serialInput}`}
                                        placeholder="Enter serial number" 
                                        value={newSerial} 
                                        onChange={handleSerialNumberChange}
                                    />
                                    <button 
                                        type="button" 
                                        onClick={addSerialNumber}
                                        className={styles.addSerialBtn}
                                    >
                                        Add Serial
                                    </button>
                                </div>
                                <div className={`${styles.serialTags} ${styles.largerSerialBadge}`}>
                                    {formData.serialNumber.map((serial, idx) => (
                                        <SerialNumberBadge 
                                            key={serial}
                                            serialNumber={serial}
                                            onRemove={() => removeSerialNumber(idx)}
                                            isRemovable={true}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.formRow}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Weight and Unit
                            </label>
                            <div className={styles.inputGroup}>
                                <input 
                                    type="text" 
                                    className={`${styles.formInput} ${styles.weightInput}`}
                                    placeholder="Enter weight" 
                                    name="weight" 
                                    value={formData.weight} 
                                    onChange={handleInputChange}
                                />
                                <select 
                                    name="weightUnit" 
                                    value={formData.weightUnit} 
                                    onChange={handleInputChange}
                                    className={`${styles.formInput} ${styles.weightUnit}`}
                                >
                                    <option value="kg">Kilograms (kg)</option>
                                    <option value="g">Grams (g)</option>
                                    <option value="lb">Pounds (lb)</option>
                                </select>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Price
                            </label>
                            <div className={styles.inputGroup}>
                                <div className={styles.priceInputContainer}>
                                    <span className={styles.priceSymbol}>$</span>
                                    <input 
                                        type="text" 
                                        className={`${styles.formInput} ${styles.priceInput}`}
                                        placeholder="Enter price" 
                                        name="price" 
                                        value={formData.price} 
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.formRowThreeColumns}>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Warehouse Location
                            </label>
                            <select 
                                name="warehouse" 
                                value={formData.warehouse} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select warehouse</option>
                                {warehouses.map(warehouse => (
                                    <option key={warehouse._id} value={warehouse._id}>
                                        {warehouse.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Department
                            </label>
                            <select 
                                name="department" 
                                value={formData.department} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select department</option>
                                {departments.map(department => (
                                    <option key={department._id} value={department._id}>
                                        {department.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label className={`${styles.formLabel} ${styles.requiredLabel}`}>
                                Product Category
                            </label>
                            <select 
                                name="category" 
                                value={formData.category} 
                                onChange={handleInputChange}
                                className={styles.formInput}
                            >
                                <option value="">Select category</option>
                                {categories.map(category => (
                                    <option key={category._id} value={category._id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={styles.formRow}>
                        <div className={`${styles.formGroup} ${styles.fullWidth}`}>
                            <label className={styles.formLabel}>Additional Notes</label>
                            <textarea 
                                className={styles.textareaInput}
                                placeholder="Enter any additional product information" 
                                name="notes" 
                                value={formData.notes} 
                                onChange={handleInputChange}
                                rows="4"
                            />
                        </div>
                    </div>

                    <button type="submit" className={styles.submitBtn}>
                        Update Product
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UpdateProduct;