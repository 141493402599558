import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line, RiEdit2Line, RiUserAddLine } from "react-icons/ri";
import { getUsers, deleteUser } from '../../services/authService';
import { showConfirmDialog, showErrorMessage } from '../../utilis/CustomMessages';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns'; 
import styles from './UsersTable.module.css';

const isCurrentlyActive = (lastActivity) => {
    if (!lastActivity) return false;
    const twoMinutesAgo = new Date(Date.now() - 2 * 60 * 1000);
    return new Date(lastActivity) > twoMinutesAgo;
};

const UsersTable = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]); 
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setIsLoading(true);
                const data = await getUsers();
                setUsers(data || []); 
            } catch (error) {
                console.error("Error fetching users:", error);
                setError(error.message);
                showErrorMessage('Error!', 'Failed to fetch users data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const filteredUsers = users?.filter(user =>
        user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    const handleUpdate = (userId) => {
        const userToUpdate = users.find(user => user._id === userId);
        if (userToUpdate) {
            navigate('/update-user', { state: { user: userToUpdate } });
        }
    };

    const handleDelete = async (userId) => {
        const isConfirmed = await showConfirmDialog('Are you sure?', "You won't be able to revert this!");
        if (isConfirmed) {
            try {
                await deleteUser(userId);
                const updatedUsers = users.filter(user => user._id !== userId);
                setUsers(updatedUsers);
            } catch (error) {
                console.error("Error deleting user:", error);
                showErrorMessage('Error!', 'There was an error deleting the user.');
            }
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>User Management</h1>
            
            {isLoading && <div>Loading...</div>}
            {error && <div className={styles.error}>{error}</div>}
            
            {!isLoading && !error && (
                <>

                    <div className={styles.filtersContainer}>
                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>Search User</label>
                            <input
                                type="text"
                                className={styles.filterInput}
                                placeholder="Search by name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        
                        <button 
                            onClick={() => navigate('/register')} 
                            className={styles.createButton}
                        >
                            <RiUserAddLine />
                            Create User
                        </button>
                    </div>

                    <div className={styles.tableWrapper}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.tableCell}>Name</th>
                                    <th className={styles.tableCell}>Username</th>
                                    <th className={styles.tableCell}>Email</th>
                                    <th className={styles.tableCell}>Permission</th>
                                    <th className={styles.tableCell}>Status</th>
                                    <th className={styles.tableCell}>Last Active</th>
                                    <th className={styles.tableCell}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map(user => (
                                    <tr key={user._id} className={styles.tableRow}>
                                        <td className={styles.tableCell}>{user.name}</td>
                                        <td className={styles.tableCell}>{user.username}</td>
                                        <td className={styles.tableCell}>{user.email}</td>
                                        <td className={styles.tableCell}>
                                            <span className={user.permission === 'admin' ? styles.adminBadge : styles.userBadge}>
                                                {user.permission}
                                            </span>
                                        </td>
                                        <td className={styles.tableCell}>
                                            <span className={isCurrentlyActive(user.lastActivity) ? styles.activeBadge : styles.inactiveBadge}>
                                                {isCurrentlyActive(user.lastActivity) ? 'Currently Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td className={styles.tableCell}>
                                            {isCurrentlyActive(user.lastActivity) ? (
                                                'Online Now'
                                            ) : (
                                                user.lastActivity ? 
                                                formatDistanceToNow(new Date(user.lastActivity), { addSuffix: true }) 
                                                : 'Never'
                                            )}
                                        </td>
                                        <td className={`${styles.tableCell} ${styles.actionColumn}`}>
                                            <RiEdit2Line
                                                className={`${styles.actionIcon} ${styles.editIcon}`}
                                                onClick={() => handleUpdate(user._id)}
                                            />
                                            <RiDeleteBin6Line
                                                className={`${styles.actionIcon} ${styles.deleteIcon}`}
                                                onClick={() => handleDelete(user._id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default UsersTable;