import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/sidebar/SideBar";
import Layout from "./components/layout/Layout";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getLoginStatus } from "./services/authService";
import { SET_LOGIN } from "./redux/features/auth/authSlice";
import ProductsTable from "./pages/products/ProductsTable";
import ProductTableAdmin from "./pages/products/ProductTableAdmin";
import CreateOrder from "./pages/orders/CreateOrder";
import OrdersDashBoard from "./pages/orders/OrdersDashBoard";
import CreateProduct from "./pages/products/CreateProduct";
import UsersTable from "./pages/users/UsersTable";
import UpdateUser from "./pages/users/UpdateUser"; 
import UpdateProduct from "./pages/products/UpdateProduct";
import ReturnOrder from "./pages/orders/ReturnOrder";
import UpdateOrder from "./pages/orders/UpdateOrder";
import { PrivateRoute } from "./components/protectedRoute/ProtectedRoute";
import { AdminRoute } from "./components/protectedRoute/ProtectedRoute";
import CategoriesAndWarehouses from './pages/categoriesAndWarehouses/CategoriesAndWarehouses';
import AuthPage from "./pages/auth/AuthPage";
import NotFound from "./pages/notFound/NotFound";


import './global.css';


axios.defaults.withCredentials = true;

function App() {
  const dispatch = useDispatch();

  useEffect(() =>{
    async function loginStatus() {
      const status = await getLoginStatus()
      dispatch(SET_LOGIN(status))
    }
    loginStatus();
  }, [dispatch])
  
  return (
    <BrowserRouter>
      <ToastContainer/>
      <Routes>
        <Route path="/" element={<AuthPage/>}/>
        {/* <Route path="/register" element={<Register/>}/>  */}
        <Route path="/register" element={<AdminRoute><Register/></AdminRoute>}/> 

        <Route path="/dashboard" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <Dashboard/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>

        <Route path="/getproducts" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <ProductsTable/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>

        <Route path="/product-maintenance" element={
          <AdminRoute>
          <Sidebar>
            <Layout>
              <ProductTableAdmin/>
            </Layout>
          </Sidebar>
          </AdminRoute>
        }/>

        <Route path="/createproduct" element={
          <AdminRoute>
          <Sidebar>
            <Layout>
              <CreateProduct/>
            </Layout>
          </Sidebar>
          </AdminRoute>
        }/>

        <Route path="/user-maintenance" element={
          <AdminRoute>
          <Sidebar>
            <Layout>
              <UsersTable/>
            </Layout>
          </Sidebar>
          </AdminRoute>
        }/>
        <Route path="/update-user" element={
          <AdminRoute>
          <Sidebar>
            <Layout>
              <UpdateUser/>
            </Layout>
          </Sidebar>
          </AdminRoute>
        }/>
        <Route path="/categories-warehouses" element={
          <AdminRoute>
            <Sidebar>
              <Layout>
                <CategoriesAndWarehouses />
              </Layout>
            </Sidebar>
          </AdminRoute>
        }/>
        <Route path="/update-product" element={
          <AdminRoute>
          <Sidebar>
            <Layout>
              <UpdateProduct/>
            </Layout>
          </Sidebar>
          </AdminRoute>
        }/>
        <Route path="/create-order" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <CreateOrder/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>
        <Route path="/orders" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <OrdersDashBoard/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>
        <Route path="/return-order" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <ReturnOrder/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>
        <Route path="/update-order" element={
          <PrivateRoute>
          <Sidebar>
            <Layout>
              <UpdateOrder/>
            </Layout>
          </Sidebar>
          </PrivateRoute>
        }/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
