import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/warehouses';

export const getWarehouses = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const createWarehouse = async (warehouseData) => {
    const response = await axios.post(API_URL, warehouseData);
    return response.data;
};

export const deleteWarehouse = async (warehouseId) => {
    const response = await axios.delete(`${API_URL}/${warehouseId}`);
    return response.data;
};

export const updateWarehouse = async (warehouseId, warehouseData) => {
    const response = await axios.put(`${API_URL}/${warehouseId}`, warehouseData);
    return response.data;
};