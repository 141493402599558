import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <p>
          © {currentYear} Elbit Systems. All Rights Reserved.
        </p>
        <p>
          Design and programming by{' '}
          <a 
            href="https://granumcode.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className={styles.link}
          >
            Granum Code
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;