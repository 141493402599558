import React, { useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.css';

const activeSubLink = ({ isActive }) => (isActive ? styles.active : styles.link);
const activeLink = ({ isActive }) => (isActive ? styles.active : styles.link);

const SideBarItem = ({ item, isOpen, setIsOpen }) => {
    const [expandMenu, setExpandMenu] = useState(false);

    const handleItemClick = () => {
        if (!isOpen && (item.title === "Orders" || item.title === "System Maintenance")) {
            setIsOpen(true);
        }
        if (item.childrens) {
            setExpandMenu(!expandMenu);
        }
    };

    if (item.childrens) {
        return (
            <div 
                className={`${styles.sidebarItem} ${styles.sParent} ${expandMenu ? styles.open : ''}`}
                onClick={handleItemClick}
            >
                <div className={styles.sidebarTitle}>
                    <span className={styles.titleSpan}>
                        {item.icon && <div className={styles.icon}>{item.icon}</div>}
                        {isOpen && <div className={styles.titleText}>{item.title}</div>}
                    </span>
                    <div className={styles.arrowIconWrapper}>
                        <MdKeyboardArrowRight className={styles.arrowIcon} />
                    </div>
                </div>
                <div className={styles.sidebarContent}>
                    {item.childrens.map((child, index) => {
                        return (
                            <div key={index} className={styles.sChild}>
                                <NavLink 
                                    to={child.path} 
                                    className={activeSubLink}
                                >
                                    <div className={styles.sidebarItem}>
                                        <div className={styles.sidebarTitle}>
                                            <span className={styles.titleSpan}>
                                                {child.icon && <div className={styles.icon}>{child.icon}</div>}
                                                {isOpen && <div className={styles.titleText}>{child.title}</div>}
                                            </span>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <NavLink to={item.path} className={activeLink}>
            <div 
                className={`${styles.sidebarItem} ${styles.sParent}`}
                onClick={() => {
                    if (!isOpen && (item.title === "Orders" || item.title === "System Maintenance")) {
                        setIsOpen(true);
                    }
                }}
            >
                <div className={styles.sidebarTitle}>
                    <span className={styles.titleSpan}>
                        {item.icon && <div className={styles.icon}>{item.icon}</div>}
                        {isOpen && <div className={styles.titleText}>{item.title}</div>}
                    </span>
                </div>
            </div>
        </NavLink>
    );
};

export default SideBarItem;