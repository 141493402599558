import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SerialNumberBadge from './SerialNumberBadge';
import OrderStatusBadge from '../order/OrderStatusBadge';
import './ProductDetailsModal.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const formatDateTime = (dateString) => {
    if (!dateString) return 'Not available';
    
    try {
      return new Date(dateString).toLocaleString('en-US', {
        timeZone: 'Asia/Jerusalem',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid date';
    }
  };

const ProductDetailsModal = ({ productId, onClose }) => {
    const [product, setProduct] = useState(null);
    const [relatedOrders, setRelatedOrders] = useState([]);
    const [category, setCategory] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [department, setDepartment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const overlayRef = useRef(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const [productResponse, ordersResponse] = await Promise.all([
                    axios.get(`${BACKEND_URL}/api/products/${productId}`),
                    axios.get(`${BACKEND_URL}/api/orders/getorders`)
                ]);

                const productData = productResponse.data;
                setProduct(productData);

                // Fetch category and warehouse details
                const [categoryResponse, warehouseResponse, departmentResponse] = await Promise.all([
                    axios.get(`${BACKEND_URL}/api/categories/${productData.category}`),
                    axios.get(`${BACKEND_URL}/api/warehouses/${productData.warehouse}`),
                    axios.get(`${BACKEND_URL}/api/departments/${productData.department}`)

                ]);

                setCategory(categoryResponse.data);
                setWarehouse(warehouseResponse.data);
                setDepartment(departmentResponse.data);
                // Filter orders that contain this product and are either "committed" or "issued"
                const relevantOrders = ordersResponse.data.filter(order => 
                    order.products.some(p => p.product === productId) &&
                    (order.orderStatus === 'committed' || order.orderStatus === 'issued')
                );
                setRelatedOrders(relevantOrders);
            } catch (error) {
                console.error('Error fetching product details:', error);
                toast.error('Failed to fetch product information');
            } finally {
                setIsLoading(false);
            }
        };

        if (productId) {
            fetchProductDetails();
        }
    }, [productId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && overlayRef.current === event.target) {
                onClose();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);

    if (!product || isLoading) return <div className="loader">Loading...</div>;
    console.log(product);

    return (
        <div className="modal-overlay" ref={overlayRef}>
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>×</button>

                {/* Header Section */}
                <div className="modal-header">
                    <div className="product-basic-info">
                        <h2>{product.productName}</h2>
                        <p className="product-code">{product.productCode}</p>
                    </div>
                </div>

                {/* Main Product Information */}
                <div className="product-details-grid">
                    <div className="info-section">
                        <h3>General Information</h3>
                        <div className="info-grid">
                            <div className="info-item">
                                <span className="info-label">Total Quantity:</span>
                                <span className="info-value">{product.quantity}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Available Quantity:</span>
                                <span className="info-value">{product.quantityAvailable}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Committed Quantity:</span>
                                <span className="info-value">{product.commitedQuantity}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Weight:</span>
                                <span className="info-value">{product.weight} {product.weightUnit}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Price:</span>
                                <span className="info-value">${product.price.toFixed(2)}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Managed By Serial:</span>
                                <span className="info-value">{product.managedBySerial ? 'Yes' : 'No'}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Category:</span>
                                <span className="info-value">{category ? category.name : 'Loading...'}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Warehouse:</span>
                                <span className="info-value">{warehouse ? warehouse.name : 'Loading...'}</span>
                            </div>
                            <div className="info-item">
                                <span className="info-label">Department:</span>
                                <span className="info-value">{department ? department.name : 'Loading...'}</span>
                            </div>
                            {product.createdAt && (
                            <div className="info-item">
                                <span className="info-label">Created:</span>
                                <span className="info-value">{formatDateTime(product.createdAt)}</span>
                            </div>
                            )}
                        </div>
                    </div>

                    {/* Serial Numbers Section */}
                    {product.managedBySerial && (
                        <div className="serial-numbers-section">
                            <h3>Serial Numbers</h3>
                            <div className="serial-numbers-container">
                                <div className="serial-numbers-column">
                                    <h4>All Serial Numbers ({product.serialNumber.length})</h4>
                                    <div className="serial-numbers-list">
                                        {product.serialNumber.map((serial) => (
                                            <SerialNumberBadge key={serial} serialNumber={serial} />
                                        ))}
                                    </div>
                                </div>
                                <div className="serial-numbers-column">
                                    <h4>Available Serial Numbers ({product.serialNumberAvailable.length})</h4>
                                    <div className="serial-numbers-list">
                                        {product.serialNumberAvailable.map((serial) => (
                                            <SerialNumberBadge key={serial} serialNumber={serial} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Related Orders Section */}
                    <div className="related-orders-section">
                        <h3>Related Orders</h3>
                        <table className="related-orders-table">
                            <thead>
                                <tr>
                                    <th>Order Number</th>
                                    <th>Status</th>
                                    <th>Quantity</th>
                                    <th>Destination</th>
                                    <th>Dates</th>
                                    {product.managedBySerial && <th>Serial Numbers</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedOrders.map(order => {
                                    const productInOrder = order.products.find(p => p.product === productId);
                                    return (
                                        <tr key={order._id}>
                                            <td>{order.orderNumber}</td>
                                            <td>
                                                <OrderStatusBadge status={order.orderStatus} />
                                            </td>
                                            <td>{productInOrder?.quantity || 0}</td>
                                            <td>{order.destination}</td>
                                            <td>
                                                {new Date(order.fromDate).toLocaleDateString('he-IL')} - 
                                                {new Date(order.untilDate).toLocaleDateString('he-IL')}
                                            </td>
                                            {product.managedBySerial && (
                                               <td>
                                                    {productInOrder?.serialNumbers ? 
                                                        <div className="serial-numbers-cell">
                                                            {productInOrder.serialNumbers.map((serial, index) => (
                                                                <SerialNumberBadge 
                                                                    key={`${order._id}-${serial}-${index}`} 
                                                                    serialNumber={serial} 
                                                                />
                                                            ))}
                                                        </div>
                                                    : 'N/A'}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* Notes Section */}
                    {product.notes && (
                        <div className="notes-section">
                            <h3>Notes</h3>
                            <p>{product.notes}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductDetailsModal;