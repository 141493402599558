import { createSlice } from '@reduxjs/toolkit'

// Safe JSON parse helper
const safeJSONParse = (item, defaultValue) => {
    try {
        const parsed = localStorage.getItem(item);
        return parsed ? JSON.parse(parsed) : defaultValue;
    } catch (error) {
        return defaultValue;
    }
};

// Safe localStorage getters with defaults
const name = safeJSONParse("name", "");
const user = safeJSONParse("user", {
    name: "", 
    username: "",
    email: "",
    permission: "",
});
const isLoggedIn = safeJSONParse("isLoggedIn", false);

const initialState = {
    isLoggedIn,
    name,
    user,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        SET_LOGIN(state, action) {
            state.isLoggedIn = action.payload;
            localStorage.setItem('isLoggedIn', JSON.stringify(action.payload));
        },
        SET_NAME(state, action) {
            state.name = action.payload;
            localStorage.setItem("name", JSON.stringify(action.payload));
        },
        SET_USER(state, action) {
            const profile = action.payload;
            state.user = profile;
            localStorage.setItem("user", JSON.stringify(profile));
        },
    }
});

export const {SET_LOGIN, SET_NAME, SET_USER} = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectName = (state) => state.auth.name;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;