import Swal from 'sweetalert2';

// Function to show a success message
export const showSuccessMessage = (title, text) => {
    Swal.fire({
        title,
        text,
        icon: 'success',
        confirmButtonText: 'OK'
    });
};

// Function to show an error message
export const showErrorMessage = (title, text) => {
    Swal.fire({
        title,
        text,
        icon: 'error',
        confirmButtonText: 'OK'
    });
};

// Function to show an info message
export const showInfoMessage = (title, text) => {
    Swal.fire({
        title,
        text,
        icon: 'info',
        confirmButtonText: 'OK'
    });
};

// Function to show a warning message
export const showWarningMessage = (title, text) => {
    Swal.fire({
        title,
        text,
        icon: 'warning',
        confirmButtonText: 'OK'
    });
};

// Function to show a confirmation dialog
export const showConfirmDialog = async (title, text, confirmButtonText = 'Yes, confirm it!') => {
    const result = await Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText
    });

    return result.isConfirmed;
};