// departmentService.js
import axios from 'axios';
const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/departments';

export const getDepartments = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const createDepartment = async (departmentData) => {
    const response = await axios.post(API_URL, departmentData);
    return response.data;
};

export const deleteDepartment = async (departmentId) => {
    const response = await axios.delete(`${API_URL}/${departmentId}`);
    return response.data;
};

export const updateDepartment = async (departmentId, departmentData) => {
    const response = await axios.put(`${API_URL}/${departmentId}`, departmentData);
    return response.data;
};