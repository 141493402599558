import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './ReturnOrderDetail.module.css';

const ReturnOrderDetail = ({ order, onClose, onSubmit }) => {
    const [returnProducts, setReturnProducts] = useState([]);

    useEffect(() => {
        if (order && order.products) {
            const initialReturnProducts = order.products.map(product => {
                const remainingQuantity = 
                    (product.initialQuantity || product.quantity) - 
                    ((product.returnedQuantity || 0) + (product.missingQuantity || 0));

                const availableSerialNumbers = product.serialNumbers ? 
                    product.serialNumbers.filter(sn => 
                        !(product.returnedSerialNumbers || []).includes(sn) &&
                        !(product.missingSerialNumbers || []).includes(sn)
                    ) : [];

                return {
                    ...product,
                    remainingQuantity,
                    returnedQuantity: remainingQuantity,
                    missingQuantity: 0,
                    serialNumbersReturned: availableSerialNumbers,
                    missingSerialNumbers: [],
                    availableSerialNumbers
                };
            });
            setReturnProducts(initialReturnProducts);
        }
    }, [order]);

    const handleQuantityChange = (productId, value) => {
        setReturnProducts(prev => 
            prev.map(product => {
                if (product._id === productId) {
                    const updatedProduct = { ...product };
                    const newValue = Math.min(Math.max(0, value), product.remainingQuantity);
                    
                    updatedProduct.returnedQuantity = newValue;
                    updatedProduct.missingQuantity = product.remainingQuantity - newValue;
                    
                    return updatedProduct;
                }
                return product;
            })
        );
    };

    const handleSerialNumberStatus = (productId, serialNumber, isChecked) => {
        setReturnProducts(prev => 
            prev.map(product => {
                if (product._id === productId) {
                    const updatedProduct = { ...product };
                    
                    if (isChecked) {
                        updatedProduct.serialNumbersReturned = [...updatedProduct.serialNumbersReturned, serialNumber];
                        updatedProduct.missingSerialNumbers = updatedProduct.missingSerialNumbers.filter(sn => sn !== serialNumber);
                    } else {
                        updatedProduct.serialNumbersReturned = 
                            updatedProduct.serialNumbersReturned.filter(sn => sn !== serialNumber);
                        updatedProduct.missingSerialNumbers = [...updatedProduct.missingSerialNumbers, serialNumber];
                    }
    
                    updatedProduct.returnedQuantity = updatedProduct.serialNumbersReturned.length;
                    updatedProduct.missingQuantity = updatedProduct.remainingQuantity - updatedProduct.returnedQuantity;
                    
                    return updatedProduct;
                }
                return product;
            })
        );
    };

    const handleSubmitReturn = async () => {
        try {
            // Validate return data
            const invalidProducts = returnProducts.filter(product => {
                const totalReturned = (product.returnedQuantity || 0) + (product.missingQuantity || 0);
                return totalReturned > product.remainingQuantity;
            });

            if (invalidProducts.length > 0) {
                toast.error('Invalid return quantities. Please check your entries.');
                return;
            }
            const returnData = returnProducts.map(product => ({
                productId: product._id,
                returnedQuantity: product.returnedQuantity,
                serialNumbersReturned: product.serialNumbersReturned,
                missingQuantity: product.missingQuantity,
                missingSerialNumbers: product.missingSerialNumbers
            }));

            await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/orders/return/${order._id}`, {
                products: returnData
            });

            toast.success('Order returned successfully');
            onSubmit(returnData);
            onClose();
        } catch (error) {
            toast.error('Failed to return order');
            console.error(error);
        }
    };

    return (
        <div className={styles.modal}>
            <div className={styles.content}>
                <h2 className={styles.title}>Return Order Details</h2>
                <div className={styles.orderInfo}>
                    <p><strong>Order Number:</strong> {order.orderNumber}</p>
                    <p><strong>Order Name:</strong> {order.orderName}</p>
                    <p><strong>Destination:</strong> {order.destination}</p>
                </div>
                
                {returnProducts.length > 0 && (
                    <div>
                        <table className={styles.productsTable}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Returned Quantity</th>
                                    <th>Missing Quantity</th>
                                    {returnProducts.some(p => p.availableSerialNumbers?.length > 0) && 
                                        <th>Serial Numbers</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {returnProducts.map(product => (
                                    <tr key={product._id}>
                                        <td>{product.productName}</td>
                                        <td>{product.remainingQuantity}</td>
                                        <td>
                                            {product.availableSerialNumbers?.length > 0 ? (
                                                product.returnedQuantity
                                            ) : (
                                                <input 
                                                    type="number"
                                                    value={product.returnedQuantity}
                                                    min={0}
                                                    max={product.remainingQuantity}
                                                    onChange={(e) => handleQuantityChange(
                                                        product._id, 
                                                        Number(e.target.value)
                                                    )}
                                                    className={styles.quantityInput}
                                                />
                                            )}
                                        </td>
                                        <td>{product.missingQuantity}</td>
                                        {product.availableSerialNumbers?.length > 0 && (
                                            <td>
                                                <div className={styles.serialSelection}>
                                                    {product.availableSerialNumbers.map(serialNumber => (
                                                        <div key={serialNumber} className={styles.serialItem}>
                                                            <span>{serialNumber}</span>
                                                            <input
                                                                type="checkbox"
                                                                checked={product.serialNumbersReturned.includes(serialNumber)}
                                                                onChange={(e) => handleSerialNumberStatus(
                                                                    product._id,
                                                                    serialNumber,
                                                                    e.target.checked
                                                                )}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                <div className={styles.actionButtons}>
                    <button onClick={onClose} className={styles.cancelButton}>
                        Cancel
                    </button>
                    <button 
                        onClick={handleSubmitReturn} 
                        className={styles.submitButton}
                        disabled={returnProducts.every(p => p.returnedQuantity === 0 && p.missingQuantity === 0)}
                    >
                        Submit Return
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReturnOrderDetail;