import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUser } from '../../services/authService';
import Loader from '../../loader/Loader';
import { RiRegisteredLine } from "react-icons/ri";
import styles from './UpdateUser.module.css';

const UpdateUser = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const initialState = {
        name: "",
        username: "",
        password: "",
        email: "",
        isAdmin: false,
    };

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const { name, username, password, email, isAdmin } = formData;

    useEffect(() => {
        if (location.state && location.state.user) {
            setFormData({
                name: location.state.user.name,
                username: location.state.user.username,
                password: '', 
                email: location.state.user.email,
                isAdmin: location.state.user.permission === 'admin'
            });
        }
    }, [location.state]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name || !username || !email) {
            return toast.error("All fields are required");
        }

        const userData = {
            name,
            username,
            email,
            permission: isAdmin ? 'admin' : 'user'
        };

        if (password.trim() !== '') {
            userData.password = password;
        }

        setIsLoading(true);
        try {
            await updateUser(location.state.user._id, userData);
            setIsLoading(false);
            navigate('/user-maintenance');
        } catch (error) {
            setIsLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div className={styles.container}>
            {isLoading && <Loader />}
            <div className={styles.formCard}>
                <div className={styles.topLine}></div>
                <button 
                    className={styles.backButton}
                    onClick={() => navigate('/user-maintenance')}
                >
                    ← Back
                </button>
                
                <div className={styles.iconContainer}>
                    <RiRegisteredLine size={35} />
                </div>
                
                <h2 className={styles.title}>Update User</h2>
                
                <form onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="name" className={styles.label}>
                            Name
                            <span className={styles.required}>*</span>
                        </label>
                        <input 
                            id="name"
                            type="text" 
                            placeholder="Enter full name"
                            required 
                            name='name' 
                            value={name} 
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="username" className={styles.label}>
                            Username
                            <span className={styles.required}>*</span>
                        </label>
                        <input 
                            id="username"
                            type="text" 
                            placeholder="Enter employee number"
                            required 
                            name='username' 
                            value={username} 
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="password" className={styles.label}>
                            New Password
                        </label>
                        <input 
                            id="password"
                            type="password" 
                            placeholder="Leave blank to keep current password"
                            name='password' 
                            value={password} 
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="email" className={styles.label}>
                            Email
                            <span className={styles.required}>*</span>
                        </label>
                        <input 
                            id="email"
                            type="email" 
                            placeholder="Enter email address"
                            required 
                            name='email' 
                            value={email} 
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>
                    
                    <div className={styles.checkboxGroup}>
                        <label htmlFor="isAdmin" className={styles.checkboxLabel}>
                            <input 
                                id="isAdmin"
                                type="checkbox" 
                                name="isAdmin" 
                                checked={isAdmin} 
                                onChange={handleInputChange} 
                                className={styles.checkbox}
                            />
                            <span>Admin Access</span>
                        </label>
                    </div>
                    
                    <button type="submit" className={styles.submitButton}>
                        Update User
                    </button>
                </form>
            </div>
        </div>
    );
}

export default UpdateUser;